import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const setModalConfig = (config) => {
  return {
    type: actionTypes.SET_MODAL_CONFIG,
    config: config,
  };
};

export const closeModal = () => {
  return {
    type: actionTypes.CLOSE_MODAL,
  };
};

export const openModal = (type, mode, id, data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setModalConfig(response.data.config));
    };
    let url = false;

    switch (mode) {
      case 'attach':
        url = '/api/modals/getconfig/' + mode + '/' + type + '/' + id;
        break;
      case 'edit':
        url = '/api/modals/getconfig/' + mode + '/' + type + '/' + id;
        break;
      case 'destroy':
        url = '/api/modals/getconfig/' + mode + '/' + type + '/' + id;
        break;
      case 'create':
        url = '/api/modals/getconfig/' + mode + '/' + type;
        break;
      default:
        break;
    }

    let obj = {
      url: url,
      method: 'post',
      data: data || {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};
