import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  hiddenColumns: {},
};

const setHiddenColumns = (state, action) => {
  return {
    ...state,
    hiddenColumns: {
      ...state.hiddenColumns,
      [action.tableName]: action.hiddenColumns,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HIDDEN_COLUMNS:
      return setHiddenColumns(state, action);
    default:
      return state;
  }
};

export default reducer;
