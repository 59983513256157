import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
};

const setSuppliersList = (state, action) => {
  return {
    ...state,
    list: action.suppliers,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPLIERS_LIST:
      return setSuppliersList(state, action);
    default:
      return state;
  }
};

export default reducer;
