import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {closeModal} from './modals';
import {setTableParameters} from './shared';

export const setSuppliers = (data) => {
  return {
    type: actionTypes.SET_SUPPLIERS_LIST,
    suppliers: data.suppliers,
  };
};

export const createSupplier = (data, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSuppliers(tableParameters));
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/suppliers/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Fornitore creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const editSupplier = (data, id, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSuppliers(tableParameters));
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/suppliers/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Fornitore modificato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroySupplier = (data, id, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSuppliers(tableParameters));
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/suppliers/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Fornitore eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const getSuppliers = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setSuppliers(response.data));
    };

    let obj = {
      url: '/api/suppliers/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
