import React, { useEffect, useState, useCallback } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiCall } from '../../Redux/Actions/apiCall';
import { toast } from 'react-toastify';
import { format, eachWeekOfInterval, addDays, parseISO, subMonths } from 'date-fns';

const getTickValues = (data, month, groupByWeek) => {
  if (groupByWeek) return data.map((item) => item.x);
  const interval = month <= 1 ? 3 : month <= 3 ? 5 : month <= 6 ? 10 : 15;
  return data.filter((_, index) => index % interval === 0).map((item) => item.x);
};

const formatSalesData = (data) => data.map((item) => ({
  x: format(item.x, 'dd/MM'), // Format for display without year
  y: item.y,
}));

const groupByWeeks = (data) => {
  if (!data.length) return [];
  
  const firstDay = data[0].x;
  const lastDay = data[data.length - 1].x;

  if (isNaN(firstDay) || isNaN(lastDay) || firstDay > lastDay) {
    console.error("Invalid date range detected in groupByWeeks:", { firstDay, lastDay });
    return [];
  }

  const weeks = eachWeekOfInterval({ start: firstDay, end: lastDay });
  
  return weeks.map((startOfWeek) => {
    const endOfWeek = addDays(startOfWeek, 6);
    const weeklySales = data.filter((d) => d.x >= startOfWeek && d.x <= endOfWeek);
    const totalSales = weeklySales.reduce((sum, d) => sum + (d.y || 0), 0);

    return {
      x: `${format(startOfWeek, 'dd/MM')} - ${format(endOfWeek, 'dd/MM')}`, // Format without year
      y: totalSales,
    };
  });
};

const SalesChart = ({ apiCall }) => {
  const { id } = useParams();
  
  // Default date setup: from one month ago to today
  const today = format(new Date(), 'yyyy-MM-dd');
  const oneMonthAgo = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
  
  const [salesData, setSalesData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [groupByWeek, setGroupByWeek] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);

  const fetchSalesData = useCallback(() => {
    if (!startDate || !endDate) return;

    setLoading(true);
    setError(null);

    apiCall({
      url: `/api/orderdetails/sales/${id}`,
      method: 'post',
      data: { start_date: startDate, end_date: endDate },
      onSuccess: (response) => {
        const sales = response.data?.data;
        if (sales && sales.length) {
          const parsedData = sales.map((item) => ({
            x: parseISO(item.day),
            y: parseFloat(item.total_sell_price),
          }));

          setOriginalData(parsedData);
          setSalesData(parsedData);
        } else {
          setOriginalData([]);
          setSalesData([]);
          toast.info('No sales data available for the selected date range');
        }
        setLoading(false);
      },
      onFailure: (error) => {
        toast.error(error.data?.exception || 'Failed to load sales data');
        setError(error.message);
        setLoading(false);
      },
    });
  }, [startDate, endDate, id, apiCall]);

  useEffect(() => {
    fetchSalesData(); // Automatically fetch data on mount with default dates
  }, [id, fetchSalesData]);

  useEffect(() => {
    // Fetch sales data when date range changes
    fetchSalesData();
  }, [startDate, endDate, fetchSalesData]);

  useEffect(() => {
    setSalesData(groupByWeek ? groupByWeeks(originalData) : formatSalesData(originalData));
  }, [groupByWeek, originalData]);

  return (
    <div>
      <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
        <label>
          Start Date:{' '}
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label style={{ marginLeft: '1rem' }}>
          End Date:{' '}
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <div style={{ marginTop: '1rem' }}>
          <label>
            <input
              type="checkbox"
              checked={groupByWeek}
              onChange={(e) => setGroupByWeek(e.target.checked)}
            />
            Group by Week
          </label>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {error}</div>
      ) : salesData.length === 0 ? (
        <div>No sales data available for the selected date range.</div>
      ) : (
        <div style={{ height: '400px' }}>
          <ResponsiveBar
            data={salesData}
            keys={['y']}
            indexBy="x"
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            enableLabel={false}
            tooltip={({ id, value, indexValue }) => (
              <div style={{ padding: '5px 10px', background: '#fff', border: '1px solid #ccc' }}>
                <strong>{indexValue}</strong>: {value}
              </div>
            )}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              legend: groupByWeek ? 'Week (Start - End)' : 'Date',
              legendOffset: 36,
              legendPosition: 'middle',
              tickRotation: 0,
              tickValues: getTickValues(salesData, new Date(endDate).getMonth() - new Date(startDate).getMonth() + 1, groupByWeek),
            }}
            axisLeft={{
              orient: 'left',
              legend: 'Total Sales (Price)',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default connect(null, { apiCall })(SalesChart);
