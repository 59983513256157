import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {closeModal} from './modals';
import {toast} from 'react-toastify';

export const setAttributes = (data) => {
  return {
    type: actionTypes.SET_ATTRIBUTES_LIST,
    attributesList: data.attributes,
  };
};

export const setSelectedAttribute = (data) => {
  return {
    type: actionTypes.SET_SELECTED_ATTRIBUTE,
    selectedAttribute: {
      attribute: data.attribute,
      values: data.values,
    },
  };
};

export const showAttribute = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSelectedAttribute(response.data));
    };

    let obj = {
      url: '/api/attributes/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero della funzionalità',
    };

    dispatch(apiCall(obj));
  };
};

export const createOption = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(closeModal());
      dispatch(showAttribute(response.data.idAttribute));
      toast.success('Opzione creata con successo!');
    };

    let obj = {
      url: '/api/attributes/createoption',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getAttributes = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setAttributes(response.data));
    };

    let obj = {
      url: '/api/attributes/list',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
