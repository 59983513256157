import React from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  setIsClosing,
  setNextProduct,
  fetchMessages,
  fetchThreads,
  closeDialog,
  fetchReservation,
  getNextProduct,
  resetClosingOrder,
  setClosingCart,
  changeCartState,
} from '../../Redux/Actions/index';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {setSimpleValue} from '../../Redux/simpleValues/actions';
import Echo from 'laravel-echo';
import {isAllowed} from '../../shared/functions';
import {useAuthenticator} from '@aws-amplify/ui-react';

let hidden = null;
let visibilityChange = null;
let lastTabId = new Date().toISOString();
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const WebSocket = (props) => {
  const {user} = useAuthenticator((context) => [context.user]);

  const [visible, setVisibility] = React.useState(!document[hidden]);

  let history = useHistory();

  const dispatch = useDispatch();

  const connectToWebsocket = () => {
    if (props.idUser) {
      window.io = require('socket.io-client');

      window.Echo = new Echo({
        broadcaster: 'socket.io',
        key: process.env.REACT_APP_ECHO_KEY,
        host: process.env.REACT_APP_ECHO_HOST,
        auth: {
          headers: {
            Authorization: `Bearer ${props.accessToken}`,
          },
        },
      });

      const channel = window.Echo.channel('FantaWarehouse.' + props.idUser);

      const generalChannel = window.Echo.channel('FantaWarehouse.General');

      if (
        isAllowed(
          ['admin', 'wadmin', 'commercial', 'worker', 'superworker'],
          user?.signInUserSession?.idToken?.payload['cognito:groups']
        )
      ) {
        channel.listen('.GetNextRelocateRow', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            if (payload.notEnough) {
              toast.error('Quantità richieste già riservate, riprova');
              history.push('/relocation');
            } else if (payload.isPastReservation) {
              toast.info('Avevi già una prenotazione pendente!');
              dispatch({
                type: 'SET_RELOCATE_RESERVATION',
                payload: payload,
              });
              props.closeDialog();
              history.push('/relocation/myreservation');
            } else {
              toast.success('Quantità riservate con successo!');
              dispatch({
                type: 'SET_RELOCATE_RESERVATION',
                payload: payload,
              });
              props.closeDialog();
              history.push('/relocation/myreservation');
            }
          }
        });

        // channel.listen('.setUnreadMessages', function (payload) {
        //   if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
        //     if (
        //       payload.messages.unreadMessage !== false &&
        //       props.actualUnread !== payload.messages.unreadMessage
        //     ) {
        //       dispatch({
        //         type: 'SET_UNREAD_MESSAGE',
        //         unreadMessage: payload.messages.unreadMessage,
        //         notifyThem: payload.messages.messageArray,
        //       });
        //       if (history.location.pathname === '/mailbox') {
        //         props.fetchMessages(props.selectedThread, props.idUser);
        //         props.fetchThreads(props.idUser);
        //       }
        //     }
        //   }
        // });

        channel.listen('.setSystemAlert', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            // eslint-disable-next-line

            dispatch({
              type: 'SET_UNREAD_ALERTS',
              unreadAlerts: payload.alerts.unreadAlerts,
              notifyThem: payload.alerts.notifyThem,
              recentAlerts: payload.alerts.recentAlerts,
            });
          }
        });

        generalChannel.listen('.SetSystemStatus', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            dispatch({type: 'SET_SYSTEM_STATUS', status: payload.status});
          }
        });

        generalChannel.listen('.SetNeedRelocation', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            dispatch({
              type: 'SET_NEED_RELOCATION',
              needRelocation: payload.needRelocation,
            });
          }
        });

        generalChannel.listen('.setSkuInventoryRemoval', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            dispatch({
              type: 'SET_INVENTORY_REMOVAL_ROWS',
              inventoryRemoval: payload.inventoryRemoval,
            });
          }
        });

        generalChannel.listen('.setInventoryControlRows', function (payload) {
          if (window.localStorage.getItem('lastVisibleTabId') === lastTabId) {
            dispatch({
              type: 'SET_INVENTORY_CONTROL_ROWS',
              inventoryControl: payload.inventoryControl,
            });
          }
        });

        channel.listen('.GetNextProduct', function (payload) {
          // payload.currentCartId
          /* if(!props.isRequest) { */
          if (payload.noProducts) {
            props.setIsClosing();
            props.changeCartState(payload.currentCartId, 3, 0);
            toast.success('Raccolta completata!');
            history.push('/shipmentcenter');
          } else {
            if (payload.uuid !== '') {
              props.fetchReservation(payload.uuid, 'picking');
              history.push('/shipmentcenter/collecting');
            } else {
              props.setIsClosing();
              if (payload.currentCartId) {
                props.changeCartState(payload.currentCartId, 3, 0);
              }
              toast.success('CHIAMA SUBITO THOMAS!EMERGENZA!');
              history.push('/shipmentcenter');
            }
          }
          /* } */
          // eslint-disable-next-line
        });

        channel.listen('.GetNextOrder', function (payload) {
          if (payload.errors) {
            // TODO: handle the error creating a dialog, rather than some useless Toasts
            payload.errors.map((error) => {
              toast.error(`${error.idOrder} -> ${error.message}`);
              return null;
            });
          }

          if (payload.withOrder) {
            props.fetchReservation(payload.uuid, 'closing');
            history.push('/shipmentcenter/closing');
          } else {
            props.resetClosingOrder();
            props.setClosingCart(false, '');
            if (payload.needQuit) {
              toast.success('Processo di chiusura concluso');
            } else {
              toast.info('Nessun ordine valido per questo carrello');
            }
            history.push('/shipmentcenter');
          }
        });
      }
    } else {
      if (window.Echo) {
        window.Echo.disconnect();
      }
    }
  };

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      setVisibility(false);
    } else {
      setVisibility(true);
      window.localStorage.setItem('lastVisibleTabId', lastTabId);
    }
  };

  React.useEffect(() => {
    connectToWebsocket();

    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
    // eslint-disable-next-line
  }, [props.idUser]);

  React.useEffect(() => {
    if (visible) {
      window.localStorage.setItem('lastVisibleTabId', lastTabId);
    }

    return () => {};
    // eslint-disable-next-line
  }, [visible]);

  return <React.Fragment />;
};

const mapStateToProps = (state) => {
  return {
    isRequest: state.config.isRequest,
    idUser: state.auth.userId,
    accessToken: state.auth.accessToken,
    unreadMessage: state.message.unread,
    messageNotifyThem: state.message.notifyThem,
    alertsNotifyThem: state.alerts.notifyThem,
    selectedThread: state.message.selectedThread,
    pickingCart: state.shipmentcenter.carts.pickingCart,
    lastVisibleTabId: state.simpleValues.lastVisibleTabId,
  };
};

const mapDispatchToProps = {
  setIsClosing,
  setNextProduct,
  fetchReservation,
  fetchMessages,
  fetchThreads,
  getNextProduct,
  closeDialog,
  resetClosingOrder,
  setClosingCart,
  setSimpleValue,
  changeCartState,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket);
