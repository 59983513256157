import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  skuOwnersList: false,
  selectedSkuOwner: false,
};

const setSkuOwnersList = (state, action) => {
  return {
    ...state,
    skuOwnersList: action.skuOwnersList,
  };
};

const setSelectedSkuOwner = (state, action) => {
  return {
    ...state,
    selectedSkuOwner: action.selectedSkuOwner,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SKU_OWNERS_LIST:
      return setSkuOwnersList(state, action);
    case actionTypes.SET_SELECTED_SKU_OWNER:
      return setSelectedSkuOwner(state, action);
    default:
      return state;
  }
};

export default reducer;
