import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  createdProductId: false,
  selectedProduct: {
    data: {},
    attributes: null,
    image: {
      base64: false,
      isNew: false,
    },
    needSave: false,
    selectedPanel: 0,
  },
};

const setCreatedProductId = (state, action) => {
  return {
    ...state,
    createdProductId: action.idProduct,
  };
};

const resetCreatedProductId = (state, action) => {
  return {
    ...state,
    createdProductId: false,
  };
};

const changeSelectedProductPanel = (state, action) => {
  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      selectedPanel: action.idPanel,
    },
  };
};

const handleChangeToMultipleValues = (state, action) => {
  let obj = [...state.selectedProduct.attributes];

  Object.values(obj).forEach((el) => {
    if (el.id === action.id) {
      let pushThat = true;
      el.value.forEach((thisValue, index) => {
        if (thisValue === parseInt(action.value)) {
          el.value.splice(index, 1);
          pushThat = false;
        }
      });
      if (pushThat) {
        el.value.push(parseInt(action.value));
      }
    }
  });

  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      attributes: obj,
      needSave: true,
    },
  };
};

const editSelectedProduct = (state, action) => {
  let obj = [...state.selectedProduct.attributes];

  Object.values(obj).forEach((el) => {
    if (el.id === action.id) {
      el.value = action.value;
      el.isChanged = true;
    }
  });

  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      attributes: obj,
      needSave: true,
    },
  };
};

const raiseChangedAttributeEvent = (state, action) => {
  const obj = [...state.selectedProduct.attributes];
  const attr = obj.findIndex(prop => prop.id === action.id);

  if (attr >= 0) {
    obj[attr].raiseEvent = action.value;
  }

  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      attributes: obj,
    }
  }
};

const resetSelectedProduct = (state, action) => {
  return {
    ...state,
    selectedProduct: initialState.selectedProduct,
  };
};

const setCreatedProductData = (state, action) => {
  switch (action.fieldName) {
    case 'name':
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          data: {
            ...state.selectedProduct.data,
            name: action.value,
          },
          needSave: true,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

const setSelectedProduct = (state, action) => {
  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      data: action.product,
      attributes: action.attributes,
      image: {
        base64: action.image,
        isNew: false,
      },
      needSave: false,
    },
  };
};

const changeSelectedProductImage = (state, action) => {
  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      data: {
        ...state.selectedProduct.data,
      },
      image: {
        base64: action.image,
        isNew: true,
      },
      needSave: true,
    },
  };
};

const setProductsList = (state, action) => {
  return {
    ...state,
    list: action.products,
  };
};

const setBaseProductsList = (state, action) => {
  return {
    ...state,
    baseProducts: action.baseProducts,
  };
};

const setBaseProductCategoriesList = (state, action) => {
  return {
    ...state,
    baseProductCategories: action.baseProductCategories,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PRODUCTS_LIST:
      return setProductsList(state, action);
    case actionTypes.SET_BASE_PRODUCTS_LIST:
      return setBaseProductsList(state, action);
    case actionTypes.SET_BASE_PRODUCTS_CATEGORIES_LIST:
      return setBaseProductCategoriesList(state, action);
    case actionTypes.SET_SELECTED_PRODUCT:
      return setSelectedProduct(state, action);
    case actionTypes.RESET_SELECTED_PRODUCT:
      return resetSelectedProduct(state, action);
    case actionTypes.EDIT_SELECTED_PRODUCT:
      return editSelectedProduct(state, action);
    case actionTypes.RAISE_CHANGED_ATTRIBUTE_EVENT:
      return raiseChangedAttributeEvent(state, action);
    case actionTypes.EDIT_MULTIPLE_SELECTED_PRODUCT:
      return handleChangeToMultipleValues(state, action);
    case actionTypes.CHANGE_SELECTED_PRODUCT_PANEL:
      return changeSelectedProductPanel(state, action);
    case actionTypes.CHANGE_SELECTED_PRODUCT_IMAGE:
      return changeSelectedProductImage(state, action);
    case actionTypes.SET_CREATED_PRODUCT_ID:
      return setCreatedProductId(state, action);
    case actionTypes.CHANGE_SELECTED_PRODUCT_DATA:
      return setCreatedProductData(state, action);
    case actionTypes.RESET_CREATED_PRODUCT_ID:
      return resetCreatedProductId(state, action);
    default:
      return state;
  }
};

export default reducer;
