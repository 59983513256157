import * as types from './types';

export default function simpleValues(state = {}, action) {
  switch (action.type) {
    case types.ON_SIMPLE_VALUE_CHANGED:
      return {...state, [action.id]: action.value};
    case types.ON_SIMPLE_VALUE_UPDATED:
      return {
        ...state,
        [action.id]: {...state[action.id], [action.property]: action.value},
      };
    case types.GET_SIMPLE_VALUE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
}
