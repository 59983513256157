import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {setTableParameters} from './shared';

export const setMissionData = (data) => {
  return {
    type: actionTypes.SET_MISSION_DATA,
    types: data.types,
    owners: data.owners,
    users: data.users,
  };
};

export const setMissionsList = (data) => {
  return {
    type: actionTypes.SET_MISSION_LIST,
    list: data.list,
  };
};

export const setChildMissionsList = (data) => {
  return {
    type: actionTypes.SET_CHILD_MISSION_LIST,
    list: data.list,
  };
};

export const fetchMissions = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setMissionsList(response.data));
    };

    let obj = {
      url: '/api/missions/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei dati',
    };

    dispatch(apiCall(obj));
  };
};

export const fetchChildMissions = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setChildMissionsList(response.data));
    };

    let obj = {
      url: '/api/missions/child',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei dati',
    };

    dispatch(apiCall(obj));
  };
};

export const resetMissionList = () => {
  return {
    type: actionTypes.RESET_MISSION_LIST,
  };
};

export const createMission = (type, property) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(resetCreateMission());
      dispatch(fetchMissionData());
    };

    let obj = {
      url: '/api/missions/create',
      method: 'post',
      data: {type: type, property: property},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Missione creata con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const setMissionProperty = (type, value) => {
  return {
    type: actionTypes.SET_MISSION_PROPERTY,
    propertyType: type,
    value: value,
  };
};

export const resetCreateMission = () => {
  return {
    type: actionTypes.RESET_CREATE_MISSION,
  };
};

export const setMissionType = (selectedType, idOwner) => {
  let property = false;
  switch (selectedType) {
    case 'manualPick':
      property = {
        idOwner: idOwner,
        sku: false,
        quantity: false,
      };
      break;
    case 'inventoryControl':
      property = {
        idOwner: idOwner,
        sku: false,
      };
      break;
    case 'inventoryRemoval':
      property = {
        idOwner: idOwner,
        sku: false,
      };
      break;
    case 'inventoryRelocations':
      property = {
        idOwner: idOwner,
        sku: false,
        location: false,
      };
      break;
    default:
      break;
  }

  return {
    type: actionTypes.SET_MISSION_TYPE,
    selectedType: selectedType,
    property: property,
  };
};

export const fetchMissionData = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setMissionData(response.data));
    };

    let obj = {
      url: '/api/missions/fetchdata',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dei dati',
    };

    dispatch(apiCall(obj));
  };
};
