import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  attributesList: false,
  selectedAttribute: false,
};

const setAttributesList = (state, action) => {
  return {
    ...state,
    attributesList: action.attributesList,
  };
};

const setSelectedAttribute = (state, action) => {
  return {
    ...state,
    selectedAttribute: {
      attribute: action.selectedAttribute.attribute,
      values: action.selectedAttribute.values,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ATTRIBUTES_LIST:
      return setAttributesList(state, action);
    case actionTypes.SET_SELECTED_ATTRIBUTE:
      return setSelectedAttribute(state, action);
    default:
      return state;
  }
};

export default reducer;
