import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  brandsList: false,
  selectedBrand: false,
};

const setBrandsList = (state, action) => {
  return {
    ...state,
    brandsList: action.brandsList,
  };
};

const setSelectedBrand = (state, action) => {
  return {
    ...state,
    selectedBrand: action.brand,
  };
};

const editSelectedBrandValue = (state, action) => {
  switch (action.valueType) {
    case 'name':
      return {
        ...state,
        selectedBrand: {
          ...state.selectedBrand,
          name: action.value,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BRANDS_LIST:
      return setBrandsList(state, action);
    case actionTypes.SET_SELECTED_BRAND:
      return setSelectedBrand(state, action);
    case actionTypes.EDIT_SELECTED_BRAND_VALUE:
      return editSelectedBrandValue(state, action);
    default:
      return state;
  }
};

export default reducer;
