import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const setProductMovements = (data) => {
  return {
    type: actionTypes.SET_PRODUCT_MOVEMENTS_LIST,
    movements: data.movements,
    sortKey: data.sortKey,
    sortOrder: data.sortOrder,
    searchData: data.searchData,
    currentPage: data.currentPage,
    rowsPerPage: data.rowsPerPage,
    resultsCount: data.resultsCount,
    pageCount: data.pageCount,
  };
};

export const getProductMovements = (searchAndSortData, page) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setProductMovements(response.data));
    };

    let obj = {
      url: '/api/productmovements/list?page=' + page,
      method: 'post',
      data: searchAndSortData,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
