import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {setTableParameters} from './shared';

export const setInventories = (inventories) => {
  return {
    type: actionTypes.SET_CHECK_INVENTORIES_LIST,
    inventories: inventories,
  };
};

export const setInventoryShelvings = (inventoryShelvings) => {
  return {
    type: actionTypes.SET_CHECK_INVENTORY_SHELVINGS_LIST,
    inventoryShelvings: inventoryShelvings,
  };
};

export const setSelectedCheckInventory = (selectedInventory) => {
  return {
    type: actionTypes.SET_SELECTED_CHECK_INVENTORY,
    selectedInventory: selectedInventory,
  };
};

export const setSelectedCheckInventoryShelf = (selectedShelf) => {
  return {
    type: actionTypes.SET_SELECTED_CHECK_INVENTORY_SHELF,
    selectedShelf: selectedShelf,
  };
};

export const setInventorySingleShelfLocations = (singleShelfLocations) => {
  return {
    type: actionTypes.SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS,
    singleShelfLocations: singleShelfLocations,
  };
};

export const setInventorySingleShelfLocationsChecked = (
  singleShelfLocations
) => {
  if (singleShelfLocations) {
    let singleShelfLocationsChecked = singleShelfLocations.map((element) => {
      return {
        idProduct: element.idProduct,
        idSku: element.idSku,
        idCombination: element.idCombination,
        checked: false,
      };
    });
    return {
      type: actionTypes.SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED,
      singleShelfLocationsChecked: singleShelfLocationsChecked,
    };
  } else {
    return {
      type: actionTypes.SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED,
      singleShelfLocationsChecked: singleShelfLocations,
    };
  }
};

export const singleLocationsCheck = (
  singleShelfLocationsChecked,
  idProduct,
  idSku,
  idCombination,
  checked
) => {
  let singleShelfLocationsCheckedToReturn = singleShelfLocationsChecked.map(
    (element) => {
      if (
        element.idProduct === idProduct &&
        element.idSku === idSku &&
        element.idCombination === idCombination
      ) {
        return {
          idProduct: element.idProduct,
          idSku: element.idSku,
          idCombination: element.idCombination,
          checked: checked,
        };
      }
      return {
        idProduct: element.idProduct,
        idSku: element.idSku,
        idCombination: element.idCombination,
        checked: element.checked,
      };
    }
  );
  return {
    type: actionTypes.SET_SINGLE_LOCATIONS_CHECK,
    singleShelfLocationsChecked: singleShelfLocationsCheckedToReturn,
  };
};

export const addInventorySingleShelfLocationsChecked = (skulocation) => {
  const toAdd = {
    idProduct: skulocation.idProduct,
    idSku: skulocation.idSku,
    idCombination: skulocation.idCombination,
    checked: false,
  };
  return {
    type: actionTypes.ADD_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED,
    toAdd: toAdd,
  };
};

export const changeInventoryState = (
  state,
  idInventory,
  idInventoryShelf,
  tableParameters = false
) => {
  return (dispatch) => {
    let onThen = () => {
      if (tableParameters) {
        dispatch(
          getCheckInventoryShelvings(idInventory, tableParameters, true)
        );
      } else {
        dispatch(getCheckInventoryShelvings(idInventory));
      }
    };

    let obj = {
      url:
        '/api/inventories/' + idInventory + '/changeState/' + idInventoryShelf,
      method: 'post',
      data: {state: state},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getCheckInventorySingleShelf = (
  idInventory,
  idInventoryShelf,
  update = false
) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSelectedCheckInventory(response.data.inventory));
      dispatch(setSelectedCheckInventoryShelf(response.data.shelf));
      dispatch(setInventorySingleShelfLocations(response.data.skuLocations));
      if (!update) {
        dispatch(
          setInventorySingleShelfLocationsChecked(response.data.skuLocations)
        );
      }
    };

    let obj = {
      url:
        '/api/inventories/show/' + idInventory + '/shelf/' + idInventoryShelf,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getCheckInventoryShelvings = (
  id,
  tableParameters,
  update = false
) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (response.data.tableParameters && !update) {
        dispatch(setTableParameters(response.data.tableParameters));
      }
      dispatch(setSelectedCheckInventory(response.data.inventory));
      dispatch(setInventoryShelvings(response.data.inventoryShelvings));
    };

    let obj = {
      url: '/api/inventories/show/' + id,
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getCheckInventories = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (tableParameters) {
        dispatch(setTableParameters(response.data.tableParameters));
      }
      dispatch(setInventories(response.data.inventories));
    };

    let obj = {
      url: '/api/inventories/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const createCheckInventories = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCheckInventories());
    };

    let obj = {
      url: '/api/inventories/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Inventario creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const inventoryFixSkuQtyInShelf = (
  idInventory,
  idInventoryShelf,
  idOwner,
  idShelf,
  idSku,
  idCombination,
  variation
) => {
  let data = {
    idInventoryShelf,
    idOwner,
    idShelf,
    idSku,
    idCombination,
    variation,
  };

  return (dispatch) => {
    let onThen = (response) => {
      dispatch(
        getCheckInventorySingleShelf(idInventory, idInventoryShelf, true)
      );
      if (response.data.skuLocation) {
        dispatch(
          addInventorySingleShelfLocationsChecked({
            idProduct: response.data.skuLocation.idProduct,
            idSku: response.data.skuLocation.idSku,
            idCombination: response.data.skuLocation.idCombination || -1,
          })
        );
      }
    };

    let obj = {
      url: '/api/inventories/skulocation',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto aggiunto con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};
