import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const setCategories = (data) => {
  return {
    type: actionTypes.SET_CATEGORIES_LIST,
    tree: data.tree || false,
    allCategories: data.allCategories || false,
    marketplaceCategories: data.marketplaceCategories || false,
  };
};

export const editCategory = (data, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCategories());
    };

    let obj = {
      url: '/api/categories/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Categoria modificata con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyCategory = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCategories());
    };

    let obj = {
      url: '/api/categories/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Categoria eliminata con successo',
      errorMessage: 'Errore durante la rimozione',
    };

    dispatch(apiCall(obj));
  };
};

export const createCategory = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCategories());
    };

    let obj = {
      url: '/api/categories/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Categoria creata con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getCategories = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCategories(response.data));
    };

    let obj = {
      url: '/api/categories/list',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};
