import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {closeDialog, setTableParameters} from './shared';

export const setBrands = (data) => {
  return {
    type: actionTypes.SET_BRANDS_LIST,
    brandsList: data.brands,
  };
};

export const setSelectedBrand = (brand) => {
  return {
    type: actionTypes.SET_SELECTED_BRAND,
    brand: brand,
  };
};

export const editReduxBrandValue = (parameters) => {
  return {
    type: actionTypes.EDIT_SELECTED_BRAND_VALUE,
    valueType: parameters.valueType,
    value: parameters.value,
  };
};

export const createBrand = (data, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getBrands(tableParameters));
    };

    let obj = {
      url: '/api/brands/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Marca creata con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getBrands = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setBrands(response.data));
    };

    let obj = {
      url: '/api/brands/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const submitBrandChanges = (brand, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getBrands(tableParameters));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/brands/edit',
      method: 'post',
      data: {brand: brand},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: 'Marca modificata con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};
