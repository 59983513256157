import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  open: false,
  config: false,
};

const closeModalState = (state, action) => {
  return {
    ...state,
    open: false,
    config: false,
  };
};

const setModalsState = (state, action) => {
  return {
    ...state,
    open: true,
    config: action.config,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_MODAL:
      return closeModalState(state, action);
    case actionTypes.SET_MODAL_CONFIG:
      return setModalsState(state, action);
    default:
      return state;
  }
};

export default reducer;
