import {SET_START_REQUEST} from './actionTypes';
import {apiAction} from '../../middleware/api';
import * as actionTypes from './actionTypes';
export const apiCall = (payload) => {
  return function (dispatch) {
    if (!payload.noPendingRequest) {
      dispatch({type: SET_START_REQUEST, isRequest: true});
      //window.scrollTo({ top: 0 });
    }
    dispatch(apiAction(payload));
  };
};

export const isRequest = (data) => {
  return {
    type: actionTypes.SET_START_REQUEST,
    isRequest: data,
  };
};
