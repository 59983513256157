import React, {useEffect} from 'react';
import {forceRedirect, resetRedirect} from '../../Redux/Actions/index';
import {useHistory} from 'react-router-dom';
import {connect} from 'react-redux';

const ForceRedirect = (props) => {
  let history = useHistory();

  useEffect(() => {
    if (props.needRedirect) {
      history.push(props.needRedirect);
      props.resetRedirect();
    }
    // eslint-disable-next-line
  }, [props.needRedirect]);

  return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state) => {
  return {
    needRedirect: state.config.forceRedirect,
  };
};

const mapDispatchToProps = {
  forceRedirect,
  resetRedirect,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForceRedirect);
