import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  list: false,
  selectedProduct: {
    data: {},
    details: [],
  },
};

const resetSelectedPresaleProduct = (state) => {
  return {
    ...state,
    selectedProduct: initialState.selectedProduct,
  };
};

const setSelectedPresaleProduct = (state, action) => {
  return {
    ...state,
    selectedProduct: {
      ...state.selectedProduct,
      ...action.data,
    },
  };
};

const setPreorderProductsList = (state, action) => {
  return {
    ...state,
    list: action.products,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PREORDER_PRODUCTS_LIST:
      return setPreorderProductsList(state, action);
    case actionTypes.RESET_PREORDER_PRODUCTS_LIST:
      return resetSelectedPresaleProduct(state, action);
    case actionTypes.SET_SELECTED_PREORDER_PRODUCT:
      return setSelectedPresaleProduct(state, action);
    default:
      return state;
  }
};

export default reducer;
