import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const fetchAlerts = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setAlertsList(data));
    };

    let obj = {
      url: '/api/alerts/index',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore di comunicazione',
    };

    dispatch(apiCall(obj));
  };
};

export const setAlertsList = (data) => {
  return {
    type: actionTypes.SET_ALERTS_LIST,
    alertsList: data.alerts,
  };
};

export const setUnreadAlerts = (data) => {
  return {
    type: actionTypes.SET_UNREAD_ALERTS,
    unreadAlerts: data.unreadAlerts,
    notifyThem: data.notifyThem,
    recentAlerts: data.recentAlerts,
  };
};
