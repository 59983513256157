import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  recentAlerts: [],
  notifyThem: 0,
  unread: 0,
  alertsList: [],
};

const setUnreadAlerts = (state, action) => {
  return {
    ...state,
    unread: action.unreadAlerts,
    notifyThem: action.notifyThem,
    recentAlerts: action.recentAlerts,
  };
};

const setAlertsList = (state, action) => {
  return {
    ...state,
    alertsList: action.alertsList,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UNREAD_ALERTS:
      return setUnreadAlerts(state, action);
    case actionTypes.SET_ALERTS_LIST:
      return setAlertsList(state, action);
    default:
      return state;
  }
};

export default reducer;
