import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';

export const setBoxes = (data) => {
  return {
    type: actionTypes.SET_BOXES_LIST,
    boxesList: data.boxesList,
  };
};

export const getBoxes = (idMarketplace) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setBoxes(response.data));
    };

    let obj = {
      url: '/api/boxes/list',
      method: 'post',
      data: {idMarketplace: idMarketplace},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
