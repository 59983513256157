import * as types from './types';
import {apiAction} from '../../middleware/api';

/**
 *
 * @param id
 * @param value
 * @returns {{id: *, type: *, value: *}}
 */
export const setSimpleValue = (id, value) => {
  return {
    type: types.ON_SIMPLE_VALUE_CHANGED,
    id,
    value,
  };
};
/**
 *
 * @param id
 * @param property
 * @param value
 * @returns {{property: *, id: *, type: *, value: *}}
 */
export const updateSimpleValue = (id, property, value) => {
  return {
    type: types.ON_SIMPLE_VALUE_UPDATED,
    id,
    property,
    value,
  };
};
/**
 *
 * @param model
 * @returns {{type: *, value: *, key: *}}
 */
const setSimpleValueCallback = (model) => {
  return {
    type: types.GET_SIMPLE_VALUE,
    key: model.key,
    value: model.value,
  };
};
/**
 *
 * @param url
 * @param endpoint
 * @param callback
 * @param data
 * @param errorMessage
 * @returns {{payload: {headers: *, method: *, data: *, errorMessage: *, onFailure: *, callback: *, withAuth: *, label: *, successMessage: *, url: *, onSuccess: *, hiddenLoader: *}, type: *}}
 */
export const getSimpleValue = (
  url,
  endpoint,
  callback = null,
  data = null,
  errorMessage = null
) =>
  apiAction({
    url: url,
    method: 'POST',
    withAuth: endpoint.withAuth,
    data: data,
    onSuccess: setSimpleValueCallback,
    callback: callback,
    label: types.GET_SIMPLE_VALUE,
    errorMessage: errorMessage,
  });
