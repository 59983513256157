const devEnv = process.env.NODE_ENV;

const theme = {
  palette: {
    primary: {
      //main: '#FFF', //'#F48927',
      main:
        devEnv === 'local' || devEnv === 'development' ? '#db1a23' : '#37474f',
    },
    secondary: {main: '#F48927'},
  },
  tableCells: {
    main: '#F5F6F6',
    total: {
      main: '#CCEACD',
      text: '#028731',
    },
    negativeTotal: {
      main: '#eacdcc',
      text: '#870216',
    },
  },
  icons: {
    main: 'white', //'#000000',
  },
};

export default theme;
