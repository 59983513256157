import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  fields: false,
  sortKey: 'id',
  sortOrder: 'desc',
  searchData: false,
  currentPage: 1,
  rowsPerPage: 15,
  resultsCount: 0,
  pageCount: null,
  skuLocations: [],
};

const setLocationsList = (state, action) => {
  return {
    ...state,
    list: action.locations.list,
    fields: action.locations.fields,
    sortKey: action.sortKey,
    sortOrder: action.sortOrder,
    searchData: action.searchData,
    currentPage: action.currentPage,
    rowsPerPage: action.rowsPerPage,
    resultsCount: action.resultsCount,
    pageCount: action.pageCount,
  };
};

const setSkuLocations = (state, action) => {
  return {
    ...state,
    skuLocations: action.locations,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCATIONS_LIST:
      return setLocationsList(state, action);
    case actionTypes.SET_SKU_LOCATIONS:
      return setSkuLocations(state, action);
    default:
      return state;
  }
};

export default reducer;
