import {apiCall} from './apiCall';
import {openModal} from './modals';
import * as actionTypes from './actionTypes';
import {editSelectedSupplierOrder} from './supplierOrders';

export const createSupplierOrderProduct = (id, idOrder) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(openModal('supplierOrder', 'attach', idOrder));
    };

    let obj = {
      url: '/api/supplierorderproducts/create',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto inserito con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const syncSupplierOrderPrices = (idOrder, sameSupplier) => {
  return (dispatch) => {
    let onThen = (response) => {
      let arrayToIterate = response.data?.supplierOrder?.products || [];
      arrayToIterate.forEach((product) => {
        dispatch(
          editSelectedSupplierOrder(
            parseFloat(product.pricePerUnit),
            'pricePerUnit',
            product.id
          )
        );
      });
      //dispatch(getSupplierOrder(idOrder));
    };

    let obj = {
      url: '/api/supplierorderproducts/syncprices',
      method: 'post',
      data: {
        idOrder: idOrder,
        sameSupplier: sameSupplier,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prezzi sincronizzati con successo',
      errorMessage: 'Errore durante la sincronizzazione',
    };

    dispatch(apiCall(obj));
  };
};

export const destroySupplierOrderProduct = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch({
        type: actionTypes.UNSET_SELECTED_SUPPLIER_ORDER_PRODUCT,
        id: id,
      });
    };

    let obj = {
      url: '/api/supplierorderproducts/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};
