import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  types: false,
  owners: false,
  users: false,
  selectedType: false,
  missionProperty: false,
  missionsList: false,
  childMissionsList: [],
};

const setMissionsReports = (state, action) => {
  return {
    ...state,
    missionsList: action.list,
  };
};

const resetMissionsReports = (state) => {
  return {
    ...state,
    missionsList: initialState.missionsList,
  };
};

const setChildMissionsReports = (state, action) => {
  return {
    ...state,
    childMissionsList: action.list,
  };
};
const setMissionData = (state, action) => {
  return {
    ...state,
    types: action.types,
    owners: action.owners,
    users: action.users,
  };
};

const setMissionProperty = (state, action) => {
  let actualSku;
  switch (action.propertyType) {
    case 'idOwner':
      return {
        ...state,
        missionProperty: {
          ...state.missionProperty,
          idOwner: action.value,
        },
      };
    case 'idCombination':
      actualSku = {...state?.missionProperty?.sku};

      actualSku.idCombination = action.value.idCombination;

      return {
        ...state,
        missionProperty: {
          ...state.missionProperty,
          sku: actualSku,
        },
      };
    case 'sku':
      return {
        ...state,
        missionProperty: {
          ...state.missionProperty,
          sku: action.value,
        },
      };
    case 'quantity':
      return {
        ...state,
        missionProperty: {
          ...state.missionProperty,
          quantity: parseInt(action.value),
        },
      };
    case 'location':
      return {
        ...state,
        missionProperty: {
          ...state.missionProperty,
          location: parseInt(action.value),
        },
      };
    default:
      return {
        ...state,
      };
  }
};

const setSelectedType = (state, action) => {
  return {
    ...state,
    selectedType: action.selectedType,
    missionProperty: action.property,
  };
};

const resetCreateMission = (state) => {
  return {
    ...state,
    selectedType: initialState.selectedType,
    types: initialState.types,
    missionProperty: initialState.missionProperty,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MISSION_DATA:
      return setMissionData(state, action);
    case actionTypes.SET_MISSION_TYPE:
      return setSelectedType(state, action);
    case actionTypes.RESET_CREATE_MISSION:
      return resetCreateMission(state, action);
    case actionTypes.SET_MISSION_PROPERTY:
      return setMissionProperty(state, action);
    case actionTypes.SET_MISSION_LIST:
      return setMissionsReports(state, action);
    case actionTypes.SET_CHILD_MISSION_LIST:
      return setChildMissionsReports(state, action);
    case actionTypes.RESET_MISSION_LIST:
      return resetMissionsReports(state, action);
    default:
      return state;
  }
};

export default reducer;
