import {apiCall} from './apiCall';

export const updateSysStatus = () => {
  return (dispatch) => {
    let onThen = (response) => {};

    let obj = {
      url: '/api/systemstatus/update',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Impossibile aggiornare stato sistema',
    };

    dispatch(apiCall(obj));
  };
};

export const toggleService = (idService) => {
  return (dispatch) => {
    let onThen = (response) => {};

    let obj = {
      url: '/api/systemstatus/toggleservice',
      method: 'post',
      data: {
        idService: idService,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Servizio modificato con successo!',
      errorMessage: 'Impossibile modificare stato servizio',
    };

    dispatch(apiCall(obj));
  };
};

const setNewCustomer = (data) => {
  return {
    type: 'SET_NEW_CUSTOMER',
    percentage_new_customers_last_week: data.percentage_new_customers_last_week,
    percentage_new_customers_last_month:
      data.percentage_new_customers_last_month,
  };
};

export const getNewCustomers = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setNewCustomer(response.data));
    };

    let obj = {
      url: '/api/customer/percentages',
      method: 'get',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Impossibile recuperare i nuovi clienti',
    };

    dispatch(apiCall(obj));
  };
};
