import {toast} from 'react-toastify';
import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {closeModal} from './modals';
import {setSimpleValue} from '../../Redux/simpleValues/actions';
import {setTableParameters} from './shared';

export const setProducts = (products) => {
  return {
    type: actionTypes.SET_PRODUCTS_LIST,
    products: products,
  };
};

export const saveProduct = (id, data) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.success) {
        if (data.missingValuesArray.length > 0) {
          data.missingValuesArray.forEach((el) => {
            if (el === 'age') {
              toast.dark('Attenzione, età non settata');
            }
          });
        }

        toast.success('Prodotto salvato con successo!');
      } else {
        if (data.error) {
          toast.error(data.error);
        } else {
          toast.error('Errore. Contattare un amministratore.');
        }
      }
      dispatch(getSingleProduct(id));
    };

    let obj = {
      url: '/api/products/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il salvataggio del prodotto',
    };

    dispatch(apiCall(obj));
  };
};

export const changeProductAttribute = (id, data, callback) => {
  return (dispatch) => {
    let onThen = () => {
      toast.success('Prodotto salvato con successo!');
      if (callback) {
        callback();
      }
    };

    let obj = {
      url: '/api/products/changeproductattribute/' + id,
      method: 'post',
      data: {data},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante la modifica del prodotto',
    };

    dispatch(apiCall(obj));
  };
};

export const setCreatedProductId = (idProduct) => {
  return {
    type: actionTypes.SET_CREATED_PRODUCT_ID,
    idProduct: idProduct,
  };
};

export const resetCreatedProductId = () => {
  return {
    type: actionTypes.RESET_CREATED_PRODUCT_ID,
  };
};

export const changeImage = (image) => {
  return {
    type: actionTypes.CHANGE_SELECTED_PRODUCT_IMAGE,
    image: image,
  };
};

export const changeProductData = (fieldName, value) => {
  return {
    type: actionTypes.CHANGE_SELECTED_PRODUCT_DATA,
    fieldName: fieldName,
    value: value,
  };
};

export const changePanel = (idPanel) => {
  return {
    type: actionTypes.CHANGE_SELECTED_PRODUCT_PANEL,
    idPanel: idPanel,
  };
};

export const handleChangeOnMultiple = (id, value) => {
  return {
    type: actionTypes.EDIT_MULTIPLE_SELECTED_PRODUCT,
    id: id,
    value: value,
  };
};

export const resetSingleProduct = () => {
  return {
    type: actionTypes.RESET_SELECTED_PRODUCT,
  };
};

export const setSelectedProduct = (data) => {
  return {
    type: actionTypes.SET_SELECTED_PRODUCT,
    product: data.product,
    attributes: data.attributes,
  };
};

export const getSingleProduct = (id) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSelectedProduct(data));
      dispatch(setSimpleValue('barcodeRefresh', id));
    };

    let obj = {
      url: '/api/products/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero del prodotto',
    };

    dispatch(apiCall(obj));
  };
};

export const createProduct = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setCreatedProductId(data.idProduct));
    };

    let obj = {
      url: '/api/products/create',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const cloneProduct = (idProduct) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setCreatedProductId(data.idProduct));
    };

    let obj = {
      url: '/api/products/clone',
      method: 'post',
      data: {idProduct: idProduct},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto duplicato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const editValue = (id, value) => {
  return {
    type: actionTypes.EDIT_SELECTED_PRODUCT,
    id: id,
    value: value,
  };
};

export const raiseChangedAttributeEvent = (id, value) => {
  return {
    type: actionTypes.RAISE_CHANGED_ATTRIBUTE_EVENT,
    id,
    value,
  };
};

export const destroyProduct = (id, callback) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      callback();
    };

    let obj = {
      url: '/api/products/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const getProducts = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setProducts(response.data.productList));
    };

    let obj = {
      url: '/products',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
      lambda: true,
    };

    dispatch(apiCall(obj));
  };
};
