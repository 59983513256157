import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {closeDialog} from './shared';

export const setCarts = (data) => {
  return {
    type: actionTypes.SET_CARTS_LIST,
    cartsList: data.cartsList,
  };
};

export const setSelectedCart = (data) => {
  return {
    type: actionTypes.SET_SELECTED_CART,
    selectedCart: {
      cart: data.cart,
    },
  };
};

export const editSelectedCartValue = (name, value) => {
  return {
    type: actionTypes.EDIT_SELECTED_CART_VALUE,
    name: name,
    value: value,
  };
};

export const resetCart = (idCart) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(showCart(idCart));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/carts/resetcart',
      method: 'post',
      data: {idCart: idCart},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Carrello resettato con successo',
      errorMessage: 'Errore durante il reset',
    };

    dispatch(apiCall(obj));
  };
};

export const showCart = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSelectedCart(response.data));
    };

    let obj = {
      url: '/api/carts/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero del carrrello',
    };

    dispatch(apiCall(obj));
  };
};

export const createCart = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCarts());
    };

    let obj = {
      url: '/api/carts/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Carrello creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getCarts = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setCarts(response.data));
    };

    let obj = {
      url: '/api/carts/list',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const editCart = (cart) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(showCart(cart.id));
    };

    let obj = {
      url: '/api/carts/edit',
      method: 'post',
      data: cart,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Carrello modificato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyCart = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCarts());
    };

    let obj = {
      url: '/api/carts/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Carrello eliminato con successo',
      errorMessage: 'Errore durante la rimozione',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyCartProduct = (payload) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(showCart(payload.idCart));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/carts/destroyproduct',
      method: 'post',
      data: {
        idCartProduct: payload.idCartProduct,
        idCart: payload.idCart,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto eliminato con successo',
      errorMessage: 'Errore durante la rimozione',
    };

    dispatch(apiCall(obj));
  };
};

export const moveProductsToCart = (fromCart, toCart, cartProductsIds) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(showCart(fromCart));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/carts/moveproducts',
      method: 'post',
      data: {
        idFromCart: fromCart,
        idToCart: toCart,
        cartProductsIds,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotti spostati con successo',
      errorMessage: 'Errore durante lo spostamento',
    };

    dispatch(apiCall(obj));
  };
};
