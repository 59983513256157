import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  tree: false,
  selectedCategory: false,
  allCategories: false,
  mapCategories: false,
  marketplaceCategories: false,
};

const setCategoriesList = (state, action) => {
  return {
    ...state,
    tree: action.tree,
    allCategories: action.allCategories,
    mapCategories: action.mapCategories,
    marketplaceCategories: action.marketplaceCategories,
  };
};

const setSelectedCategoryState = (state, action) => {
  return {
    ...state,
    selectedCategory: action.selectedCategory,
    allCategories: action.allCategories,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES_LIST:
      return setCategoriesList(state, action);
    case actionTypes.SET_SELECTED_CATEGORY:
      return setSelectedCategoryState(state, action);
    default:
      return state;
  }
};

export default reducer;
