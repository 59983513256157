import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  customer: {
    percentage_new_customers_last_week: 0,
    percentage_new_customers_last_month: 0,
  },
  status: {},
};
const setCustomer = (state, action) => {
  return {
    ...state,
    customer: {
      percentage_new_customers_last_week:
        action.percentage_new_customers_last_week,
      percentage_new_customers_last_month:
        action.percentage_new_customers_last_month,
    },
  };
};
const setSystemStatus = (state, action) => {
  return {
    ...state,
    status: action.status,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SYSTEM_STATUS:
      return setSystemStatus(state, action);
    case actionTypes.SET_NEW_CUSTOMER:
      return setCustomer(state, action);
    default:
      return state;
  }
};

export default reducer;
