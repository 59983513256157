import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {closeModal} from './modals';
import {closeDialog} from './shared';
import {setTableParameters} from './shared';

export const setSkus = (skus) => {
  return {
    type: actionTypes.SET_SKUS_LIST,
    skus: skus,
  };
};

export const editSyncSkuAttribute = (
  idAttribute,
  idMarketplace,
  resourceType,
  value
) => {
  return {
    type: actionTypes.EDIT_SYNC_SKU_ATTRIBUTE,
    idAttribute: idAttribute,
    idMarketplace: idMarketplace,
    resourceType: resourceType,
    value: value,
  };
};

export const setSelectedSyncMarketplace = (idMarketplace) => {
  return {
    type: actionTypes.SET_SYNC_MARKETPLACE,
    idMarketplace: idMarketplace,
  };
};

export const resetSearchSku = () => {
  return {
    type: actionTypes.RESET_SEARCH_SKU,
  };
};

export const setSearchSkuResult = (data) => {
  return {
    type: actionTypes.SET_SKU_SEARCH_RESULT,
    result: data.result,
  };
};

export const searchSku = (
  idOwner,
  barcode = null,
  productName = null,
  type = ''
) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSearchSkuResult(data));
    };

    let obj = {
      url: '/api/skus/searchsku',
      method: 'post',
      data: {
        productName: productName,
        idOwner: idOwner,
        type: type,
        barcode: barcode,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: null,
      errorMessage: 'Errore durante la ricerca',
    };

    dispatch(apiCall(obj));
  };
};

export const saveSku = (id, data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSingleSku(id));
    };

    let obj = {
      url: '/api/skus/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku modificato con successo!',
      errorMessage: 'Errore durante il salvataggio dello sku',
    };

    dispatch(apiCall(obj));
  };
};

export const changeSkuPanel = (idPanel) => {
  return {
    type: actionTypes.CHANGE_SELECTED_SKU_PANEL,
    idPanel: idPanel,
  };
};

export const handleChangeOnMultipleSkuValue = (id, value) => {
  return {
    type: actionTypes.EDIT_MULTIPLE_SELECTED_SKU,
    id: id,
    value: value,
  };
};

export const resetSelectedSku = () => {
  return {
    type: actionTypes.RESET_SELECTED_SKU,
  };
};

export const setSelectedSku = (data) => {
  return {
    type: actionTypes.SET_SELECTED_SKU,
    sku: data.sku,
    attributes: data.attributes,
    tags: data.tags,
  };
};

export const getSingleSku = (id, groupByIdShelf = false) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSelectedSku(data));
    };

    let obj = {
      url: '/api/skus/show/' + id,
      method: 'post',
      data: {groupByIdShelf},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero dello sku',
    };

    dispatch(apiCall(obj));
  };
};

export const setRelocateList = (data) => {
  return {
    type: actionTypes.SET_SKUS_RELOCATE_LIST,
    relocateList: data.relocateList,
  };
};

export const submitCombinationImage = (
  img,
  idCombination,
  idProduct,
  idSku
) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSingleSku(idSku));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/skus/combination/setimage',
      method: 'post',
      data: {
        img: img,
        idProduct: idProduct,
        idSku: idSku,
        idCombination: idCombination,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const createSku = (idProduct, idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(getSingleSku(data.idSku));
    };

    let obj = {
      url: '/api/skus/create',
      method: 'post',
      data: {
        idProduct: idProduct,
        idOwner: idOwner,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const editSkuAttributeValue = (id, value) => {
  return {
    type: actionTypes.EDIT_SELECTED_SKU_ATTRIBUTE,
    id: id,
    value: value,
  };
};

export const editSkuTagValue = (tags) => {
  return {
    type: actionTypes.EDIT_SELECTED_SKU_TAG,
    tags: tags,
  };
};

export const editSkuCombinationValue = (idCombination, valueType, value) => {
  return {
    type: actionTypes.EDIT_SELECTED_Sku_Combination_Attribute,
    idCombination: idCombination,
    valueType: valueType,
    value: value,
  };
};

export const destroySku = (data, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/skus/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroySkuFromTable = (id, callback) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      callback();
    };

    let obj = {
      url: '/api/skus/destroy/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const resetSkuStats = () => {
  return {
    type: actionTypes.RESET_SKUS_STATS,
  };
};

export const setSkuStats = (data) => {
  return {
    type: actionTypes.SET_SKUS_STATS,
    stats: data.stats,
  };
};

export const getSkuStats = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSkuStats(response.data));
    };

    let obj = {
      url: '/api/skus/stats/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const findShelvings = (id, callback) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (typeof callback === 'function') {
        callback(response.data || []);
      }
    };

    let obj = {
      url: '/api/skus/findshelvings/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getSkus = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setSkus(response.data.skuList));
    };

    let obj = {
      url: '/skus',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
      lambda: true,
    };

    dispatch(apiCall(obj));
  };
};
