import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import NotificationsActiveIcon from '@material-ui/core/SvgIcon/SvgIcon';

const NotificationsContainer = (props) => {
  const MessageContainer = (props) => {
    return (
      <Grid container>
        <Grid item xs={2}>
          <Grid container justify="center">
            <Avatar alt={props.firstName} src={props.avatar} />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          {props.total > 1
            ? 'Hai ' +
              props.total +
              ' nuovi messaggi da ' +
              props.firstName +
              ' ' +
              props.lastName
            : 'Nuovo messaggio da ' + props.firstName + ' ' + props.lastName}
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (props.messageNotifyThem.length > 0) {
      props.messageNotifyThem.forEach((el) => {
        toast.info(
          <MessageContainer
            avatar={el.avatar}
            firstName={el.userData.firstName}
            total={el.total}
            lastName={el.userData.lastName}
          />
        );
      });
    }

    // eslint-disable-next-line
  }, [props.messageNotifyThem]);

  useEffect(() => {
    if (props.alertsNotifyThem > 0) {
      const alertNumber = parseInt(props.alertsNotifyThem);
      toast.info(<AlertContainer alertNumber={alertNumber} />);
    }

    // eslint-disable-next-line
  }, [props.alertsNotifyThem]);

  const AlertContainer = (props) => {
    return (
      <Grid container>
        <Grid item xs={2}>
          <Grid container justify="center">
            <NotificationsActiveIcon />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          {props.alertNumber > 1
            ? 'Hai ' + props.alertNumber + ' nuove notifiche di sistema!'
            : 'Nuova notifica dal sistema'}
        </Grid>
      </Grid>
    );
  };

  return <React.Fragment />;
};

const mapStateToProps = (state) => {
  return {
    idUser: state.auth.userId,
    accessToken: state.auth.accessToken,
    unreadMessage: state.message.unread,
    messageNotifyThem: state.message.notifyThem,
    alertsNotifyThem: state.alerts.notifyThem,
    selectedThread: state.message.selectedThread,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
