import moment from 'moment';
import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  config: {
    idPanel: 1,
    panels: [
      {id: 1, label: 'Elenco ordini'},
      {id: 2, label: 'Raccolta prodotti'},
      {id: 3, label: 'Chiusura pacchi'},
    ],
    shelvings: false,
  },
  orders: {
    idState: 2,
    ordersStates: false,
    list: false,
    closingCart: {
      idCart: false,
      cartName: false,
    },
    processOrder: {},
    needQuit: false,
  },
  carts: {
    idCartState: 1,
    list: false,
    pickingCart: {
      idCart: false,
      cartName: false,
      products: false,
      isRoute: false,
      isClosing: false,
      currentPosition: false,
      pendingRequest: false,
      uuid: false,
      wantQuit: false,
    },
  },
  pickingBatchesEnabled: false,
  pickingBatches: [],
  pickingBatchesForCarts: [],
  createdLabelUrl: false,
};

const emptyPickProductRow = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        products: false,
        uuid: false,
      },
    },
  };
};

const resetClosingOrder = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      processOrder: initialState.orders.processOrder,
      needQuit: false,
    },
  };
};

const setNeedQuitFromClosing = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      needQuit: true,
    },
  };
};

const putInParcel = (state, action) => {
  let newProducts = state.orders.processOrder.products;

  let selectedBox = state.orders.processOrder.selectedBox;

  let readyCheck = true;

  newProducts.forEach((el) => {
    if (el.id === action.id) {
      el.isInParcel = true;
    }
    if (!el.isInParcel) {
      readyCheck = false;
    }
  });

  if (!selectedBox) {
    readyCheck = false;
  }

  return {
    ...state,
    orders: {
      ...state.orders,
      processOrder: {
        ...state.orders.processOrder,
        products: newProducts,
        isReady: readyCheck,
      },
    },
  };
};

const putAllInParcel = (state, action) => {
  let newProducts = state.orders.processOrder.products;

  let selectedBox = state.orders.processOrder.selectedBox;

  let readyCheck = true;

  newProducts.forEach((el) => {
    el.isInParcel = true;
    if (!el.isInParcel) {
      readyCheck = false;
    }
  });

  if (!selectedBox) {
    readyCheck = false;
  }

  return {
    ...state,
    orders: {
      ...state.orders,
      processOrder: {
        ...state.orders.processOrder,
        products: newProducts,
        isReady: readyCheck,
      },
    },
  };
};

const setClosingOrder = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      processOrder: {
        ...state.orders.processOrder,
        carrier: action.order.carrier,
        unrecognized: action.order.unrecognized,
        currency: action.order.currency,
        payment: action.order.payment,
        state: action.order.state,
        customer: action.order.customer,
        address: action.order.address,
        products: action.order.products,
        coupon: action.order.coupon,
        availableBoxes: action.order.availableBoxes,
        data: action.order.data,
        label: action.order.label,
        isSet: true,
        empty: action.order.empty,
        uuid: action.uuid,
        startTime: moment().toDate(),
      },
    },
  };
};

const resetCart = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        idCart: false,
        cartName: false,
        products: false,
        isRoute: false,
        isClosing: false,
        currentPosition: false,
        countProducts: false,
        pendingRequest: false,
      },
    },
  };
};

const setIsClosing = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        isClosing: true,
        wantQuit: false,
      },
    },
  };
};

const setCarts = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      idCartState: action.idCartState,
      list: action.carts,
    },
  };
};

const setOrdersList = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      list: action.orders,
      ordersStates: action.ordersStates,
    },
  };
};

const setIdPanel = (state, action) => {
  return {
    ...state,
    config: {
      ...state.config,
      idPanel: action.idPanel,
    },
  };
};

const setIdState = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      idState: action.idState,
    },
  };
};

const setWarehouseShelvings = (state, action) => {
  return {
    ...state,
    config: {
      ...state.config,
      shelvings: action.shelvings,
    },
  };
};

const setSelectedCart = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        idCart: action.idCart,
        cartName: action.cartName,
        wantQuit: action.wantQuit,
      },
    },
  };
};

const setClosingCart = (state, action) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      closingCart: {
        ...state.orders.closingCart,
        idCart: action.idCart,
        cartName: action.cartName,
      },
    },
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        idCart: action.idCart,
        cartName: action.cartName,
      },
    },
  };
};

const setCollectNextProducts = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        products: action.products,
        isRoute: action.isRoute,
        isClosing: action.isClosing,
        uuid: action.uuid,
      },
    },
  };
};

const setCollectCart = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        idCart: action.idCart,
        cartName: action.cartName,
      },
    },
  };
};

const setSelectedBox = (state, action) => {
  let newProducts = state.orders.processOrder.products;

  let readyCheck = true;

  newProducts.forEach((el) => {
    if (!el.isInParcel) {
      readyCheck = false;
    }
  });

  return {
    ...state,
    orders: {
      ...state.orders,
      processOrder: {
        ...state.orders.processOrder,
        selectedBox: action.idBox,
        isReady: readyCheck,
      },
    },
  };
};

const setWantQuit = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        wantQuit: true,
      },
    },
  };
};

export const setCurrentPosition = (state, action) => {
  return {
    ...state,
    carts: {
      ...state.carts,
      pickingCart: {
        ...state.carts.pickingCart,
        idCart: action.idCart,
        currentPosition: action.currentPosition,
      },
    },
  };
};

export const setPickingBatches = (state, action) => {
  return {
    ...state,
    pickingBatchesEnabled: action.pickingBatchesEnabled,
    pickingBatches: action.pickingBatches,
  };
};

export const setPickingBatchesForCarts = (state, action) => {
  return {
    ...state,
    pickingBatchesForCarts: action.pickingBatchesForCarts,
  };
};

export const setCreatedLabel = (state, action) => {
  return {
    ...state,
    createdLabelUrl: action.labelUrl,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHIPMENTCENTER_ORDERS:
      return setOrdersList(state, action);
    case actionTypes.SET_SHIPMENTCENTER_IDPANEL:
      return setIdPanel(state, action);
    case actionTypes.SET_SHIPMENTCENTER_IDSTATE:
      return setIdState(state, action);
    case actionTypes.SET_SHIPMENTCENTER_CARTS:
      return setCarts(state, action);
    case actionTypes.SET_SHIPMENTCENTER_SELECTEDCART:
      return setSelectedCart(state, action);
    case actionTypes.SET_SHIPMENTCENTER_SHELVINGS:
      return setWarehouseShelvings(state, action);
    case actionTypes.SET_COLLECTPROCESS_NEXTPRODUCTS:
      return setCollectNextProducts(state, action);
    case actionTypes.SET_NEED_QUIT_FROM_CLOSING:
      return setNeedQuitFromClosing(state, action);
    case actionTypes.SET_COLLECTPROCESS_IDCART:
      return setCollectCart(state, action);
    case actionTypes.RESET_SHIPMENTCENTER_CART:
      return resetCart(state, action);
    case actionTypes.SET_SHIPMENTCENTER_ISCLOSING:
      return setIsClosing(state, action);
    case actionTypes.SET_SHIPMENTCENTER_CLOSINGCART:
      return setClosingCart(state, action);
    case actionTypes.SET_SHIPMENTCENTER_CLOSINGORDER:
      return setClosingOrder(state, action);
    case actionTypes.SHIPMENTCENTER_PUT_IN_PARCEL:
      return putInParcel(state, action);
    case actionTypes.SHIPMENTCENTER_PUT_ALL_IN_PARCEL:
      return putAllInParcel(state, action);
    case actionTypes.RESET_SHIPMENTCENTER_CLOSINGORDER:
      return resetClosingOrder(state, action);
    case actionTypes.SET_SHIPMENTCENTER_SELECTEDBOX:
      return setSelectedBox(state, action);
    case actionTypes.SHIPMENTCENTER_EMPTY_PICK_PRODUCT_ROW:
      return emptyPickProductRow(state, action);
    case actionTypes.SET_SHIPMENTCENTER_WANTQUIT:
      return setWantQuit(state, action);
    case actionTypes.SHIPMENTCENTER_SET_CURRENT_POSITION:
      return setCurrentPosition(state, action);
    case actionTypes.SET_SHIPMENTCENTER_PICKING_BATCHES:
      return setPickingBatches(state, action);
    case actionTypes.SET_PICKING_BATCHES_FOR_CARTS:
      return setPickingBatchesForCarts(state, action);
    case actionTypes.SET_SHIPMENTCENTER_CREATED_LABEL:
      return setCreatedLabel(state, action);
    default:
      return state;
  }
};

export default reducer;
