import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {setTableParameters} from './shared';
import {isAllowed} from '../../shared/functions';
import {getSingleSku} from './index';
import {setCreatedProductId} from './products';

export const setBaseProducts = (baseProducts) => {
  return {
    type: actionTypes.SET_BASE_PRODUCTS_LIST,
    baseProducts: baseProducts,
  };
};

export const setBaseProductsCategories = (baseProductCategories) => {
  return {
    type: actionTypes.SET_BASE_PRODUCTS_CATEGORIES_LIST,
    baseProductCategories: baseProductCategories,
  };
};

export const setCustomCreations = (customCreations) => {
  return {
    type: actionTypes.SET_CUSTOM_CREATIONS_LIST,
    customCreations: customCreations,
  };
};

export const setCombinationsSelected = (combinations) => {
  return {
    type: actionTypes.SET_COMBINATIONS_SELECTED,
    combinations: combinations,
  };
};

export const setCustomCreationsToApprove = (customCreationsToApprove) => {
  return {
    type: actionTypes.SET_CUSTOM_CREATIONS_TO_APPROVE_LIST,
    customCreationsToApprove: customCreationsToApprove,
  };
};

export const setCustomCreationsToPrint = (customCreationsToPrint) => {
  return {
    type: actionTypes.SET_CUSTOM_CREATIONS_TO_PRINT_LIST,
    customCreationsToPrint: customCreationsToPrint,
  };
};

export const setCustomCreationsToPrintForProduct = (customCreationsToPrintForProduct) => {
  return {
    type: actionTypes.SET_CUSTOM_CREATIONS_TO_PRINT_FOR_PRODUCT_LIST,
    customCreationsToPrintForProduct: customCreationsToPrintForProduct,
  };
};

export const createBaseProduct = (idProduct, idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(getSingleSku(data.idSku));
    };

    let obj = {
      url: '/api/creatordashboard/bases/create',
      method: 'post',
      data: {
        idProduct: idProduct,
        idOwner: idOwner,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto base creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const createCreationSku = (
  idParentSku,
  idProduct,
  idOwner,
  svg,
  images,
  formData,
  combinations
) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setCreatedProductId(data.idSku));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/create',
      method: 'post',
      data: {
        idParentSku: idParentSku,
        idProduct: idProduct,
        idOwner: idOwner,
        attributes: formData,
        svg: svg,
        img: images,
        combinations: combinations,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Sku creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getBaseProducts = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setBaseProducts(response.data.baseProducts));
    };

    let obj = {
      url: '/api/creatordashboard/bases/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const getBaseProductCategories = (baseProducts) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setBaseProductsCategories(response.data.baseProductCategories));
    };

    const ids = baseProducts.map((el) => el.id);

    let obj = {
      url: '/api/creatordashboard/bases/categories',
      method: 'post',
      data: {baseProductsIds: ids},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const getCustomCreations = (tableParameters, auth) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setCustomCreations(response.data.customCreations));
    };

    let userId = isAllowed(['admin'], auth.userGroups) ? -1 : auth.userId;

    let obj = {
      url: '/api/creatordashboard/customcreations/list',
      method: 'post',
      data: {tableParameters: tableParameters, userId},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const getCustomCreationsToApprove = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(
        setCustomCreationsToApprove(response.data.customCreationsToApprove)
      );
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/needapproval',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const getProductsToPrint = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (tableParameters) {
        dispatch(setTableParameters(response.data.tableParameters));
      }
      dispatch(setCustomCreationsToPrint(response.data.customCreationsToPrint));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/needprint',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const getProductsToPrintForProduct = (
    idSku,
    idCombination,
    tableParameters
  ) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (tableParameters) {
        dispatch(setTableParameters(response.data.tableParameters));
      }
      dispatch(setCustomCreationsToPrintForProduct(response.data.customCreationsToPrint));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/needprintdetails',
      method: 'post',
      data: { idSku, idCombination, tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
}

export const setCreationApproved = (tableParameters, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getCustomCreationsToApprove(tableParameters));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/approve',
      method: 'post',
      data: {id},
      onSuccess: () => onThen(),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const setProductPrinted = (data, userId) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getProductsToPrint());
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/setprinted',
      method: 'post',
      data: {...data, userId},
      onSuccess: () => onThen(),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};

export const setProductPrintedForOrder = (data, userId) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getProductsToPrint());
      dispatch(getProductsToPrintForProduct(data.idSku, data.idCombination));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/setprinted',
      method: 'post',
      data: {...data, userId},
      onSuccess: () => onThen(),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };
    dispatch(apiCall(obj));
  };
};
export const setSkuAsBaseProduct = (id, value) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSingleSku(id));
    };

    let obj = {
      url: '/api/creatordashboard/customcreations/setbaseproduct',
      method: 'post',
      data: {id, value},
      onSuccess: () => onThen(),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};
