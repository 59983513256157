import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  accessToken: null,
  email: null,
  userId: null,
  userLevel: 0,
  firstName: '',
  lastName: '',
  defaultLang: 'it-IT',
  defaultIdOwner: null,
};

const authSuccess = (state, action) => {
  return {
    ...state,
    accessToken: action.accessToken,
    email: action.email,
    userId: action.userId,
    userLevel: action.userLevel,
    isActive: action.isActive,
    userGroups: action.userGroups,
    userOwners: action.userOwners,
    firstName: action.firstName,
    lastName: action.lastName,
    profilePicture: action.profilePicture,
    userIsActive: action.user_is_active,
    defaultIdOwner: action.defaultIdOwner ? action.defaultIdOwner : null,
  };
};

const authRegister = (state, action) => {
  return {
    ...state,
    accessToken: action.accessToken,
    email: action.email,
    userId: action.userId,
    userLevel: action.userLevel,
    isActive: action.isActive,
    firstName: action.firstName,
    profilePicture: action.profilePicture,
    lastName: action.lastName,
  };
};

const setProfilePicture = (state, action) => {
  return {
    ...state,
    profilePicture: action.profilePicture,
  };
};

const setProfileInfo = (state, action) => {
  return {
    ...state,
    firstName: action.firstName,
    lastName: action.lastName,
    defaultIdOwner: action.defaultIdOwner,
  };
};

const setToken = (state, action) => {
  return {
    ...state,
    accessToken: action.accessToken,
  };
};

const authFail = (state, action) => {
  return {
    ...state,
  };
};

const authLogout = (state, action) => {
  return {
    ...initialState,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_REGISTER:
      return authRegister(state, action);
    case actionTypes.SET_PICTURE:
      return setProfilePicture(state, action);
    case actionTypes.SET_PROFILE_INFO:
      return setProfileInfo(state, action);
    case actionTypes.SET_TOKEN:
      return setToken(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return state;
  }
};

export default reducer;
