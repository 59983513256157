import {toast} from 'react-toastify';
import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {closeModal} from './modals';

export const setOrders = (data) => {
  return {
    type: actionTypes.SET_SUPPLIER_ORDERS_LIST,
    supplierOrders: data.supplierOrders,
    sortKey: data.sortKey,
    sortOrder: data.sortOrder,
    searchData: data.searchData,
    currentPage: data.currentPage,
    rowsPerPage: data.rowsPerPage,
    resultsCount: data.resultsCount,
    pageCount: data.pageCount,
  };
};

export const removeSupplierOrderProblem = (id) => {
  return {
    type: actionTypes.REMOVE_SUPPLIER_ORDER_PROBLEM,
    id: id,
  };
};

export const fixForcedQuantity = (products) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/supplierorders/fixforced',
      method: 'post',
      data: {products: products},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Quantità forzate modificate con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const relocateSupplierOrder = (idSupplierOrder) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSupplierOrder(idSupplierOrder));
    };

    let obj = {
      url: '/api/supplierorders/relocateproducts',
      method: 'post',
      data: {idSupplierOrder: idSupplierOrder},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Missione di riubicazione generata con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const createSupplierOrder = (data, filters, page) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      dispatch(getSupplierOrders(filters, page));
    };

    let obj = {
      url: '/api/supplierorders/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordine fornitore creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const editSupplierOrder = (
  order,
  info = null,
  createNewOrder = null
) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(getSupplierOrder(order.id));
    };

    let obj = {
      url: '/api/supplierorders/edit',
      method: 'post',
      data: {order: order, info: info, createNewOrder: createNewOrder},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante il salvataggio dell'ordine",
    };

    dispatch(apiCall(obj));
  };
};

export const destroySupplierOrder = (data, id, filters, page) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSupplierOrders(filters, page));
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/supplierorders/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordine fornitore eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const getSupplierOrders = (searchAndSortData, page) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setOrders(response.data));
    };

    let obj = {
      url: '/api/supplierorders/list?page=' + page,
      method: 'post',
      data: searchAndSortData,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const setSelectedSupplierOrder = (data) => {
  return {
    type: actionTypes.SET_SELECTED_SUPPLIER_ORDER,
    order: data?.order || {},
  };
};

export const getSupplierOrder = (id) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSelectedSupplierOrder(data));
    };

    let obj = {
      url: '/api/supplierorders/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Impossibile recuperare l'ordine",
    };

    dispatch(apiCall(obj));
  };
};

export const editSelectedSupplierOrder = (value, valueType, id) => {
  return {
    type: actionTypes.EDIT_SELECTED_SUPPLIER_ORDER,
    value: value,
    valueType: valueType,
    id: id,
  };
};

export const searchByName = (string, idOrder, idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({
        type: actionTypes.SET_NAME_SEARCH_RESULT,
        products: data.products,
      });
    };

    let obj = {
      url: '/api/supplierorderproducts/productsearch/name',
      method: 'post',
      data: {string: string, idOrder, idOwner: idOwner},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: null,
      errorMessage: 'Errore durante la ricerca',
    };

    dispatch(apiCall(obj));
  };
};

export const searchByBarcode = (barcode, idOrder, idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.noProduct) {
        toast.error(`Nessun prodotto trovato per il codice '${barcode}'`);
      } else {
        toast.info(`Aggiunto prodotto '${data.product.productName}'`);
        dispatch({
          type: actionTypes.PUSH_SELECTED_SUPPLIER_ORDER_PRODUCT,
          product: data.product,
        });
      }
    };

    let onFailure = () => {
      toast.error(`Nessun prodotto trovato per il codice '${barcode}'`);
    };

    let obj = {
      url: '/api/supplierorderproducts/productsearch/barcode',
      method: 'post',
      data: {barcode: barcode, idOrder, idOwner: idOwner},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => onFailure(),
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante la ricerca',
    };

    dispatch(apiCall(obj));
  };
};

export const addProductToOrder = (idSku, idOrder, idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.noProduct) {
        toast.info('nessun prodotto trovato');
      } else {
        dispatch({
          type: actionTypes.PUSH_SELECTED_SUPPLIER_ORDER_PRODUCT,
          product: data.product,
        });
      }
    };

    let obj = {
      url: '/api/supplierorders/addproduct',
      method: 'post',
      data: {idSku: idSku, idOrder, idOwner: idOwner},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: "Errore durante l'aggiunta del prodotto",
    };

    dispatch(apiCall(obj));
  };
};

export const uploadSupplierOrderCsv = (csvFile, idSupplierOrder) => {
  return (dispatch) => {
    // Prepare FormData to send the file and order ID
    const formData = new FormData();
    formData.append('file', csvFile);
    formData.append('idSupplierOrder', idSupplierOrder);

    return new Promise((resolve, reject) => {
      // Define the onSuccess handler
      const onThen = (response) => {
        toast.success('CSV caricato con successo');
        dispatch({
          type: actionTypes.UPLOAD_SUPPLIER_ORDER_CSV_SUCCESS,
          payload: response.data,
        });
        resolve(response); // Resolve the promise on success
      };

      // Define the API call object
      const obj = {
        url: '/api/supplierorderproducts/create/batch',
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onSuccess: (response) => onThen(response),
        onFailure: (error) => {
          toast.error('Errore durante il caricamento del CSV');
          reject(error); // Reject the promise on failure
        },
      };

      // Dispatch the apiCall
      dispatch(apiCall(obj));
    });
  };
};

export const downloadSampleCsv = () => {
  return (dispatch) => {
    let onThen = (response) => {
      // Use the direct URL from the response
      const url = response.data.fileUrl;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    let obj = {
      url: '/api/supplierorderproducts/downloadsamplecsv',
      method: 'get',
      onSuccess: (response) => onThen(response),
      onFailure: () => toast.error('Errore durante il download del CSV'),
      label: '',
      headers: {},
      successMessage: 'Download del CSV di esempio avviato con successo',
      errorMessage: 'Errore durante il download del CSV',
    };

    dispatch(apiCall(obj));
  };
};
