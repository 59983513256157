import {toast} from 'react-toastify';
import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  threadsList: [],
  selectedThread: 0,
  messagesList: [],
  unread: 0,
  notifyThem: [],
  availableRecipients: [],
  selectedRecipients: [],
};

const setUnreadMessage = (state, action) => {
  return {
    ...state,
    unread: action.unreadMessage,
    notifyThem: action.notifyThem,
  };
};

const setAvailableRecipients = (state, action) => {
  return {
    ...state,
    availableRecipients: action.recipients,
  };
};

const setThreadList = (state, action) => {
  return {
    ...state,
    threadsList: action.threadsList,
  };
};

const setMessageList = (state, action) => {
  return {
    ...state,
    messagesList: action.messagesList,
    selectedThread: action.selectedThread,
  };
};

const removeSelectedRecipient = (state, action) => {
  let array = [...state.selectedRecipients];

  let isDeleted = false;

  array.forEach((el, index) => {
    if (el.id === action.selectedRecipient) {
      isDeleted = true;
      array.splice(index, 1);
    }
  });

  if (!isDeleted) {
    toast.warning("Errore nell'eliminazione!");
  }

  return {
    ...state,
    selectedRecipients: array,
  };
};

const setSelectedRecipient = (state, action) => {
  let array = [...state.selectedRecipients];

  let canPush = true;

  array.forEach((el) => {
    if (el.id === action.selectedRecipient.id) {
      canPush = false;
    }
  });

  if (canPush) {
    array.push(action.selectedRecipient);
  } else {
    toast.warning('utente già esistente!');
  }

  return {
    ...state,
    selectedRecipients: array,
  };
};

const resetRecipients = (state, action) => {
  return {
    ...state,
    availableRecipients: [],
    selectedRecipients: [],
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_UNREAD_MESSAGE:
      return setUnreadMessage(state, action);
    case actionTypes.SET_THREAD_LIST:
      return setThreadList(state, action);
    case actionTypes.SET_MESSAGE_LIST:
      return setMessageList(state, action);
    case actionTypes.SET_AVAILABLE_RECIPIENTS:
      return setAvailableRecipients(state, action);
    case actionTypes.SET_SELECTED_RECIPIENTS:
      return setSelectedRecipient(state, action);
    case actionTypes.REMOVE_SELECTED_RECIPIENT:
      return removeSelectedRecipient(state, action);
    case actionTypes.RESET_RECIPIENT:
      return resetRecipients(state, action);
    default:
      return state;
  }
};

export default reducer;
