import {toast} from 'react-toastify';
import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const authSuccess = (data) => {
  localStorage.setItem('auth_token', data.accessToken);

  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: data.accessToken,
    email: data.email,
    userId: data.userId,
    userLevel: data.userLevel,
    isActive: data.isActive,
    userGroups: data.userGroups,
    userOwners: data.userOwners,
    lastName: data.lastName,
    firstName: data.firstName,
    profilePicture: data.profilePicture,
    user_is_active: data.userIsActive,
    defaultIdOwner: data.defaultIdOwner ? data.defaultIdOwner : null,
  };
};

export const refreshToken = (token) => {
  return {
    type: actionTypes.SET_TOKEN,
    accessToken: token,
  };
};

export const setProfilePicture = (picture) => {
  return {
    type: actionTypes.SET_PICTURE,
    profilePicture: picture,
  };
};

export const setProfileInfo = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_PROFILE_INFO,
      firstName: data.get('firstName'),
      lastName: data.get('lastName'),
      defaultIdOwner: parseInt(data.get('defaultIdOwner')),
    });
  };
};

export const authRefresh = (authData) => {
  return (dispatch) => {
    let onThen = (response) => {};

    let obj = {
      url: '/api/auth/refreshtoken',
      method: 'post',
      data: authData,
      onSuccess: (response) => {
        onThen(response);
      },
      onFailure: (error) => {
        dispatch(logout(error));
      },
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const authRegister = (authData) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(authSuccess(response.data));
    };

    let obj = {
      url: '/api/auth/register',
      method: 'post',
      data: authData,
      onSuccess: (response) => {
        onThen(response);
      },
      onFailure: (error) => {
        dispatch(authFail(error));
      },
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const authFail = (error) => {
  toast.error('Errore nel login');

  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem('auth_token');
  // let onThen = () => {
  //   //dispatch({type: actionTypes.AUTH_LOGOUT});
  // };

  // let obj = {
  //   url: '/api/auth/logout',
  //   method: 'post',
  //   data: {},
  //   onSuccess: (response) => {
  //     onThen();
  //   },
  //   onFailure: () => {
  //     onThen();
  //   },
  //   label: '',
  //   headers: {},
  //   successMessage: null,
  //   errorMessage: null,
  // };

  // dispatch(apiCall(obj));

  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (authData) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(authSuccess(response.data));
      // setInterval(() => {
      //   if (localStorage.getItem("auth_token")) {
      //     dispatch(authRefresh());
      //   }
      // }, 1800000);
    };

    let obj = {
      url: '/api/auth/login',
      method: 'post',
      data: authData,
      onSuccess: (response) => {
        onThen(response);
      },
      onFailure: (error) => {
        dispatch(authFail(error));
      },
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};
