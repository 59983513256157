import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {setTableParameters} from './shared';

export const setMarketplaces = (data) => {
  return {
    type: actionTypes.SET_MARKETPLACES_LIST,
    list: data.marketplaces,
  };
};
export const setAllMarketplaces = (data) => {
  return {
    type: actionTypes.SET_ALL_MARKETPLACES,
    list: data.marketplaces,
  };
};

export const selectMapProductAttribute = (value) => {
  return {
    type: actionTypes.SET_SELECTED_PRODUCT_ATTRIBUTE_MAP,
    value: value,
  };
};

export const changeSelectedMarketplaceValue = (name, value) => {
  return {
    type: actionTypes.EDIT_SELECTED_MARKETPLACE_VALUE,
    name: name,
    value: value,
  };
};

export const setMarketplaceAttributeMap = (
  attributeType,
  idResourceMarketplace,
  value
) => {
  return {
    type: actionTypes.EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_MAP,
    attributeType: attributeType,
    idResourceMarketplace: idResourceMarketplace,
    value: value,
  };
};

export const resetAllMarketplaces = () => {
  return {
    type: actionTypes.RESET_ALL_MARKETPLACES,
  };
};

export const resetSelectedAttributeType = () => {
  return {
    type: actionTypes.RESET_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE,
  };
};

export const changeSelectedAttributeType = (attributeType) => {
  return {
    type: actionTypes.EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE,
    attributeType: attributeType,
  };
};

export const resetSelectedMarkeplace = (name, value) => {
  return {
    type: actionTypes.RESET_SELECTED_MARKETPLACE,
  };
};

export const changeMarketplacePanel = (value) => {
  return {
    type: actionTypes.CHANGE_MARKETPLACE_PANEL,
    value: value,
  };
};

export const downloadAttributes = (data) => {
  return (dispatch) => {
    let onThen = (response) => {};

    let obj = {
      url: '/api/marketplaces/downloadattributes',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Download risorse inizializzato con successso!',
      errorMessage: 'Errore',
    };

    dispatch(apiCall(obj));
  };
};

export const updateAttributes = (data) => {
  return (dispatch) => {
    let onThen = (response) => {};

    let obj = {
      url: '/api/marketplaces/updateattributes',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Update attributi inizializzato con successso!',
      errorMessage: 'Errore',
    };

    dispatch(apiCall(obj));
  };
};

export const initMarketplace = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      let idMarketplace = data.get('idMarketplace');
      dispatch(getSingleMarketplace(idMarketplace));
    };

    let obj = {
      url: '/api/marketplaces/init',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Marketplace inizializzato con successo!',
      errorMessage: 'Inizializzazione fallito',
    };

    dispatch(apiCall(obj));
  };
};

export const saveMarketplace = (marketplace) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch({type: actionTypes.TOGGLE_MARKETPLACE_NEED_SAVE});
      dispatch(getSingleMarketplace(marketplace.id));
    };

    let obj = {
      url: '/api/marketplaces/edit/' + marketplace.id,
      method: 'post',
      data: {marketplace: marketplace},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Modifiche salvate con successo!',
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const setSelectedMarketplace = (data) => {
  return {
    type: actionTypes.SET_SELECTED_MARKETPLACE,
    marketplace: data.marketplace,
    collections: data.collections,
    attributeTypes: data.attributeTypes,
  };
};

export const getSingleMarketplace = (id) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSelectedMarketplace(response.data));
    };

    let obj = {
      url: '/api/marketplaces/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getMarketplaces = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setMarketplaces(response.data));
    };

    let obj = {
      url: '/api/marketplaces/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
export const getAllMarketplaces = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setAllMarketplaces(response.data));
    };

    let obj = {
      url: '/api/marketplaces/all',
      method: 'post',
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
