import {apiCall} from './apiCall';
import {closeModal} from './modals';

export const editOrderDetail = (data, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/orderdetails/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Modifica con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyOrderDetail = (data, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/orderdetails/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto rimosso successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const createOrderDetail = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/orderdetails/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto aggiunto successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};
