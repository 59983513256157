import 'react-toastify/dist/ReactToastify.css';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import React, {Suspense} from 'react';
import locales, {getLocaleMessages} from './config/locales';
import {BrowserRouter} from 'react-router-dom';
import {authSuccess, updateSysStatus} from './Redux/Actions';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {useSelector, shallowEqual} from 'react-redux';
import {Helmet} from 'react-helmet';
import {ToastContainer} from 'react-toastify';
import {Authenticator, useAuthenticator} from '@aws-amplify/ui-react';
import {Amplify, Auth, Hub} from 'aws-amplify';
import {MuiPickersUtilsProvider} from '@material-ui/pickers/';
import {default as mainTheme} from './config/theme';
import MomentUtils from '@date-io/moment';
import * as actionTypes from './Redux/Actions/actionTypes';
import RoutesSwitch from './Component/RoutesSwitch/RoutesSwitch';
import configAmplify from '../src/config/amplify';
import WebSocket from './Component/WebSocket/WebSocket';
import packageJson from '../../../package.json';
import NotificationsContainer from './Container/Notifications/NotificationsContainer';
import ForceRedirect from './Component/ForceRedirect/ForceRedirect';
import Spinner from './Component/Spinner/Spinner';
import Navbar from './Container/Navbar/Navbar';

Amplify.configure({
  Auth: configAmplify,
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainTheme.palette.primary.main,
      light: '#757ce8',
    },
    secondary: {main: '#116A96'},
    extraColors: {
      blue: '#48567EC0',
      purple: '#9C30C1C0',
      pink: '#C45688C0',
      red: '#EF6261C0',
      green: '#008731C0',
      black: '#000000C0',
      yellow: '#F2B138C0',
      orange: '#F48927C0',
      white: '#F9F8F8C0',
      gray: '#7C848440',
    },
  },
  overrides: {
    MuiTableCell: {
      body: {
        '& a': {
          '&:hover': {
            textDecoration: 'underline',
            color: '#0C4A69',
          },
          textDecoration: 'none',
          color: 'black',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#3e7693',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: '#C00',
      },
    },
    MuiButton: {
      text: {
        fontWeight: 'bold',
        backgroundColor: '#37474f',
        '&:hover': {
          backgroundColor: '#0e161a',
        },
      },
      label: {
        color: 'white',
        fontWeight: 'bold',
      },
    },
  },
});

function App(props) {
  const locale = useSelector((state) => state.locale, shallowEqual);
  const messages = {
    ...getLocaleMessages('it', locales),
  };

  const {signOut} = useAuthenticator((context) => [context.user]);

  const userAuth = async () => {
    try {
      const user = await Auth.currentSession();

      if (!user) return;

      const {jwtToken, payload} = user.idToken;
      const userAuth = {
        type: actionTypes.AUTH_SUCCESS,
        accessToken: jwtToken,
        email: payload.email,
        userId: parseInt(payload['custom:userId']),
        isActive: 1,
        userGroups: payload['cognito:groups'],
        userOwners: JSON.parse(payload['custom:userOwners']) ?? [],
        lastName: payload.family_name,
        firstName: payload.given_name,
        defaultIdOwner: payload['custom:defaultIdOwner'] ?? null,
      };

      props.authSuccess(userAuth);
      props.updateSysStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const listener = async (data) => {
    switch (data.payload.event) {
      case 'signIn':
        await userAuth();
        break;
      case 'tokenRefresh':
        await userAuth();
        break;
      case 'tokenRefresh_failure':
        signOut();
        break;
      default:
        return;
    }
  };

  Hub.listen('auth', listener);

  return (
    <Authenticator hideSignUp={true}>
      {() => (
        <div className="App">
          <Helmet>
            <title>Warehouse</title>
          </Helmet>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={theme}>
              <IntlProvider
                locale={locale}
                key={locale}
                messages={messages}
                onError={() => {
                  // todo: on release remove this prop or handle it like you need
                }}
              >
                <BrowserRouter>
                  <Navbar />
                  <ToastContainer />
                  <NotificationsContainer />
                  <ForceRedirect />
                  <WebSocket />
                  <Spinner />
                  <Suspense fallback={<Spinner />}>
                    <RoutesSwitch />
                  </Suspense>
                </BrowserRouter>
              </IntlProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          <p
            style={{
              position: 'fixed',
              bottom: 0,
              right: 10,
              color: '#b1b1b1',
              textShadow: '1px 1px 0px #000000',
              fontSize: '12px',
            }}
          >
            v. {packageJson.version}
          </p>
        </div>
      )}
    </Authenticator>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {authSuccess, updateSysStatus};

export default connect(mapStateToProps, mapDispatchToProps)(App);
