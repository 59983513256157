import {toast} from 'react-toastify';
import * as actionTypes from './actionTypes';

export const toastSuccess = (message) => {
  return function (dispatch) {
    dispatch({
      type: actionTypes.TOAST_SUCCESS,
    });
    toast.success(message);
  };
};

export const toastError = (message) => {
  return function (dispatch) {
    dispatch({
      type: actionTypes.TOAST_ERROR,
    });
    toast.error(message);
  };
};
