import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {closeDialog, setTableParameters} from './shared';
import {closeModal} from './modals';

export const setDescriptionModels = (data) => {
  return {
    type: actionTypes.SET_DESCRIPTION_MODELS,
    descriptionModels: data.models,
  };
};

export const setSelectedDescriptionModel = (model) => {
  return {
    type: actionTypes.SET_SELECTED_DESCRIPTION_MODEL,
    descriptionModel: model,
  };
};

export const editReduxDescriptionModelValue = (parameters) => {
  return {
    type: actionTypes.EDIT_SELECTED_DESCRIPTION_MODEL_VALUE,
    valueType: parameters.valueType,
    value: parameters.value,
  };
};

export const createDescriptionModels = (data, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getDescriptionModels(tableParameters));
    };

    let obj = {
      url: '/api/skus/description/models/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Modello creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getDescriptionModels = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setTableParameters(response.data.tableParameters));
      dispatch(setDescriptionModels(response.data));
    };

    let obj = {
      url: '/api/skus/description/models/list',
      method: 'post',
      data: {tableParameters: tableParameters},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getAllDescriptionModels = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setDescriptionModels(response.data));
    };

    let obj = {
      url: '/api/skus/description/models/all',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const submitDescriptionModelChanges = (
  descriptionModel,
  tableParameters
) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getDescriptionModels(tableParameters));
      dispatch(closeDialog());
    };

    let obj = {
      url: '/api/skus/description/models/edit',
      method: 'post',
      data: {descriptionModel},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: 'Modello modificato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyDescriptionModel = (data, id, tableParameters) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      dispatch(getDescriptionModels(tableParameters));
    };

    let obj = {
      url: '/api/skus/description/models/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Modello eliminato con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};
