import './spinner.css';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {Loader} from '@aws-amplify/ui-react';
import theme from '../../config/theme';

const useStyles = makeStyles((theme) => ({
  loader: {
    width: '11vw',
    [theme.breakpoints.down('sm')]: {
      width: '80vw',
    },
  },
  loaderwrapper: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '9999',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const Spinner = (props) => {
  // let items = [spin];

  // let item = items[Math.floor(Math.random() * items.length)];

  const classes = useStyles();

  return (
    <>
      {props.isRequest ? (
        <div className={classes.loaderwrapper}>
          <Loader
            size="medium"
            filledColor={theme.palette.secondary.main}
            className={classes.loader}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isRequest: state.config.isRequest,
  };
};

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
