import * as actionTypes from '../Actions/actionTypes';
import moment from 'moment';

const initialState = {
  selectedPanel: 'generic',
  logsData: false,
  filterDate: moment(),
  unlockRelocations: [],
  pickingBatchesEnabled: false,
  pickingBatches: [],
  checkQuantity: [],
  labels: [],
};

const setSysPanelLabel = (state, action) => {
  return {
    ...state,
    labels: action.label,
  };
};

const setSysPanel = (state, action) => {
  return {
    ...state,
    selectedPanel: action.selectedPanel,
  };
};

const setSysPanelFilterDate = (state, action) => {
  return {
    ...state,
    filterDate: action.filterDate,
  };
};

const setSysPanelLogData = (state, action) => {
  return {
    ...state,
    logsData: action.logsData,
  };
};

const setSysPanelGenericData = (state, action) => {
  return {
    ...state,
    genericData: action.genericData,
  };
};

const setSysPanelUnlockRelocationRows = (state, action) => {
  return {
    ...state,
    unlockRelocations: action.rows,
  };
};

const setSysPanelClosingReservationsRows = (state, action) => {
  return {
    ...state,
    closingReservations: action.closingReservations,
  };
};

const setSysPanerHandlePickingBatches = (state, action) => {
  return {
    ...state,
    pickingBatchesEnabled: action.pickingBatchesEnabled,
    pickingBatches: action.pickingBatches,
  };
};

const setSysPanelCheckQuantity = (state, action) => {
  return {
    ...state,
    checkQuantity: action.rows || [],
  };
};

const updateSysPanelCheckQuantity = (state, action) => {
  const newCheckQuantity = state.checkQuantity;
  newCheckQuantity.forEach((element) => {
    if (
      parseInt(element.idProductMarketplace) ===
      parseInt(action.toUpdate.id_product)
    ) {
      element.prestaQuantity = parseInt(action.toUpdate.quantity);
      element.difference =
        parseInt(element.warehouseQuantity) - parseInt(element.prestaQuantity);
      return;
    }
  });
  return {
    ...state,
    checkQuantity: newCheckQuantity,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SYS_PANEL:
      return setSysPanel(state, action);
    case actionTypes.SET_SYS_PANEL_LOG_DATA:
      return setSysPanelLogData(state, action);
    case actionTypes.SET_SYS_PANEL_GENERIC_DATA:
      return setSysPanelGenericData(state, action);
    case actionTypes.SET_SYS_PANEL_FILTER_DATE:
      return setSysPanelFilterDate(state, action);
    case actionTypes.SET_UNLOCK_RELOCATION_ROWS:
      return setSysPanelUnlockRelocationRows(state, action);
    case actionTypes.SET_CLOSING_RESERVATIONS_ROWS:
      return setSysPanelClosingReservationsRows(state, action);
    case actionTypes.SET_HANDLE_PICKING_BATCHES:
      return setSysPanerHandlePickingBatches(state, action);
    case actionTypes.SET_CHECK_QUANTITY_ROWS:
      return setSysPanelCheckQuantity(state, action);
    case actionTypes.UPDATE_CHECK_QUANTITY_ROWS:
      return updateSysPanelCheckQuantity(state, action);
    case actionTypes.SET_SYS_PANEL_LABELS:
      return setSysPanelLabel(state, action);
    default:
      return state;
  }
};

export default reducer;
