import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  all: false,
  list: false,
  selectedMarketplace: false,
  selectedPanel: 0,
  selectedAttributeType: '',
  selectedProductAttribute: '',
  needSave: false,
};

const selectMapProductAttribute = (state, action) => {
  return {
    ...state,
    selectedProductAttribute: action.value,
  };
};

const toogleNeedSave = (state, action) => {
  return {
    ...state,
    needSave: !state.needSave,
  };
};

const setSelectedAttributeType = (state, action) => {
  return {
    ...state,
    selectedAttributeType: action.attributeType,
  };
};

const resetSelectedAttributeType = (state, action) => {
  return {
    ...state,
    selectedAttributeType: initialState.selectedAttributeType,
  };
};

const resetAllMarketplaces = (state, action) => {
  return {
    ...state,
    all: initialState.all,
  };
};

const resetSelectedMarketplace = (state, action) => {
  return {
    ...state,
    selectedMarketplace: initialState.selectedMarketplace,
    selectedPanel: initialState.selectedPanel,
  };
};

const changeMarketplacePanel = (state, action) => {
  return {
    ...state,
    selectedPanel: action.value,
  };
};

const setMarketplacesList = (state, action) => {
  return {
    ...state,
    list: action.list,
  };
};
const setAllMarketplaces = (state, action) => {
  return {
    ...state,
    all: action.list,
  };
};

const setSelectedMarketplace = (state, action) => {
  return {
    ...state,
    selectedMarketplace: {
      data: action.marketplace,
      collections: action.collections,
      attributeTypes: action.attributeTypes,
    },
  };
};

const editSelectedMarketplaceAttributeMap = (state, action) => {
  let array = [...state.selectedMarketplace.data.attributes];
  array.forEach((el) => {
    if (el.type === action.attributeType) {
      if (el.idResourceMarketplace === action.idResourceMarketplace) {
        el.idResource = action.value;
      }
    }
  });
  return {
    ...state,
    selectedMarketplace: {
      ...state.selectedMarketplace,
      data: {
        ...state.selectedMarketplace.data,
        attributes: array,
      },
    },
    needSave: true,
  };
};

const editSelectedMarketplaceValue = (state, action) => {
  let obj = {...state?.selectedMarketplace?.data};

  switch (action.name) {
    case 'connection.endpoint':
      if (obj?.connection?.value) {
        obj.connection.value.endpoint = action.value;
      } else {
        let connection = {
          value: {
            endpoint: action.value,
          },
        };
        obj.connection = connection;
      }
      break;
    case 'connection.key':
      if (obj?.connection?.value) {
        obj.connection.value.key = action.value;
      } else {
        let connection = {
          value: {
            key: action.value,
          },
        };
        obj.connection = connection;
      }
      break;
    default:
      obj[action.name] = action.value;
      break;
  }

  return {
    ...state,
    selectedMarketplace: {
      ...state.selectedMarketplace,
      data: obj,
    },
    needSave: true,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PRODUCT_ATTRIBUTE_MAP:
      return selectMapProductAttribute(state, action);
    case actionTypes.SET_MARKETPLACES_LIST:
      return setMarketplacesList(state, action);
    case actionTypes.SET_SELECTED_MARKETPLACE:
      return setSelectedMarketplace(state, action);
    case actionTypes.EDIT_SELECTED_MARKETPLACE_VALUE:
      return editSelectedMarketplaceValue(state, action);
    case actionTypes.CHANGE_MARKETPLACE_PANEL:
      return changeMarketplacePanel(state, action);
    case actionTypes.RESET_SELECTED_MARKETPLACE:
      return resetSelectedMarketplace(state, action);
    case actionTypes.TOGGLE_MARKETPLACE_NEED_SAVE:
      return toogleNeedSave(state, action);
    case actionTypes.EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE:
      return setSelectedAttributeType(state, action);
    case actionTypes.RESET_SELECTED_MARKETPLACE_ATTRIBUTE_TYPE:
      return resetSelectedAttributeType(state, action);
    case actionTypes.EDIT_SELECTED_MARKETPLACE_ATTRIBUTE_MAP:
      return editSelectedMarketplaceAttributeMap(state, action);
    case actionTypes.SET_ALL_MARKETPLACES:
      return setAllMarketplaces(state, action);
    case actionTypes.RESET_ALL_MARKETPLACES:
      return resetAllMarketplaces(state, action);
    default:
      return state;
  }
};

export default reducer;
