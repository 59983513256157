import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {toast} from 'react-toastify';

export const setTableParameters = (tableParameters) => {
  return {
    type: actionTypes.SET_TABLE_PARAMETERS,
    tableParameters: tableParameters,
    indexName: tableParameters.indexName,
  };
};

export const resetRelocateReservation = () => {
  return {
    type: actionTypes.RESET_RELOCATE_RESERVATION,
  };
};

export const changeDialogPayloadValue = (name, value) => {
  return {
    type: actionTypes.CHANGE_DIALOG_PAYLOAD_VALUE,
    name: name,
    value: value,
  };
};

export const changeTableParameters = (parameters, indexName) => {
  return {
    type: actionTypes.CHANGE_TABLE_PARAMETERS,
    parameters: parameters,
    indexName: indexName,
  };
};

export const resetTableParameters = () => {
  return {
    type: actionTypes.RESET_TABLE_PARAMETERS,
  };
};

export const changeRelocationRowValue = (name, value) => {
  return {
    type: actionTypes.CHANGE_RELOCATION_ROW_VALUE,
    name: name,
    value: value,
  };
};

export const reserveRelocationRows = (payload) => {
  return (dispatch) => {
    let onThen = (data) => {};

    let obj = {
      url: '/api/relocate/reserve',
      method: 'post',
      data: payload,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const setCurrentPosition = (idShelf) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({
        type: actionTypes.SET_CURRENT_POSITION,
        currentPosition: data?.shelf?.position,
      });
    };

    let obj = {
      url: '/api/shelvings/show/' + idShelf,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const fetchItemRemovedList = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({
        type: actionTypes.SET_INVENTORY_REMOVAL_WITH_INFO_ROWS,
        rows: data.inventoryRemoval || [],
      });
    };

    let obj = {
      url: '/api/inventoryremoval/list',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const fetchRelocationRows = (selectedShelf, selectedCart) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (selectedShelf && data.rows.length === 0) {
        toast.info('Nessun prodotto da raccogliere per questa ubicazione');
      }

      dispatch({
        type: actionTypes.SET_RELOCATION_ROWS,
        rows: data.rows || [],
        count: data.count,
        shelvings: data.shelvings,
        carts: data.carts,
        reserved: data.reserved,
      });
    };

    let obj = {
      url: '/api/relocate/list',
      method: 'post',
      data: {
        selectedShelf: selectedShelf,
        selectedCart: selectedCart,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const relocateProduct = (
  idShelf,
  idSku,
  idCombination,
  quantity,
  reservedQuantity,
  redirect = null
) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(fetchRelocationRows());
      if (redirect) {
        redirect();
      }
      /* dispatch({type:actionTypes.SET_RELOCATION_ROWS, relocationRows:data.relocations}) */
    };

    let obj = {
      url: '/api/relocate/locate',
      method: 'post',
      data: {
        idShelf: idShelf,
        idSku: idSku,
        idCombination: idCombination,
        quantity: quantity,
        reservedQuantity: reservedQuantity,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ubicazione settata con successo',
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const missingRelocationProduct = (
  idProduct,
  idSku,
  idCombination,
  idMission
) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(fetchRelocationRows());
    };

    let obj = {
      url: '/api/relocate/missing',
      method: 'post',
      data: {
        idSku: idSku,
        idCombination: idCombination,
        idMission: idMission,
        idProduct: idProduct,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto settato come mancante',
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const toggleBarcodeReader = (value) => {
  return {
    type: actionTypes.TOGGLE_BARCODE_READER,
    value: value,
  };
};

export const toggleQrCodeReader = (value) => {
  return {
    type: actionTypes.TOGGLE_QRCODE_READER,
    value: value,
  };
};

export const openDialog = (index, parameters, prevAction = null) => {
  if (prevAction) {
    return (dispatch) => {
      dispatch(prevAction).then(() => {
        dispatch({
          type: actionTypes.OPEN_DIALOG,
          index: index,
          parameters: parameters,
        });
      });
    };
  } else {
    return {
      type: actionTypes.OPEN_DIALOG,
      index: index,
      parameters: parameters,
    };
  }
};

export const confirmItemRemoved = (idRow, isMissing) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({type: actionTypes.RESET_ITEM_TO_PICK});
      dispatch({
        type: actionTypes.SET_CURRENT_POSITION,
        currentPosition: data.position,
      });
    };

    let obj = {
      url: '/api/inventoryremoval/confirmpick',
      method: 'post',
      data: {idRow: idRow, isMissing: isMissing},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const confirmItemsRemoved = (item) => {
  return (dispatch) => {
    let onThen = () => {
      fetchItemRemovedList();
      dispatch({type: actionTypes.FORCE_REDIRECT, url: '/'});
    };

    let obj = {
      url: '/api/inventoryremoval/confirm',
      method: 'post',
      data: {item: item},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const returnNearestItemToPick = (position) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.item === false) {
        toast.success('Nessun prodotto da rimuovere');
        dispatch({type: actionTypes.FORCE_REDIRECT, url: '/'});
        dispatch({type: actionTypes.RESET_CURRENT_POSITION});
      }
      dispatch({type: actionTypes.SET_ITEM_TO_PICK, item: data.item});
    };

    let obj = {
      url: '/api/inventoryremoval/nextproduct',
      method: 'post',
      data: {position: position},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const returnNearestItemToCheck = (position) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.item === false) {
        toast.success('Nessun prodotto da controllare');
        dispatch({type: actionTypes.FORCE_REDIRECT, url: '/'});
        dispatch({type: actionTypes.RESET_CURRENT_POSITION});
      }
      dispatch({
        type: actionTypes.SET_NEAREST_ITEM_TO_CHECK,
        item: data.item,
      });
    };

    let obj = {
      url: '/api/inventorycontrol/nextproduct',
      method: 'post',
      data: {position: position},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const forceRedirect = (url) => {
  return {
    type: actionTypes.FORCE_REDIRECT,
    url: url,
  };
};

export const confirmItemCheck = (data) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({type: actionTypes.RESET_ITEM_TO_CHECK});
      dispatch({
        type: actionTypes.SET_CURRENT_POSITION,
        currentPosition: data.position,
      });
    };

    let obj = {
      url: '/api/inventorycontrol/confirmcheck',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const exitInventoryRemoval = (itemToCheck) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch({type: actionTypes.RESET_ITEM_TO_PICK});
      dispatch({type: actionTypes.RESET_CURRENT_POSITION});
      dispatch({type: actionTypes.FORCE_REDIRECT, url: '/'});
    };

    let obj = {
      url: '/api/inventoryremoval/exit',
      method: 'post',
      data: {row: itemToCheck},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const exitInventoryControl = (itemToCheck) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch({type: actionTypes.RESET_ITEM_TO_CHECK});
      dispatch({type: actionTypes.RESET_CURRENT_POSITION});
      dispatch({type: actionTypes.FORCE_REDIRECT, url: '/'});
    };

    let obj = {
      url: '/api/inventorycontrol/exit',
      method: 'post',
      data: {row: itemToCheck},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const resetRedirect = (url) => {
  return {
    type: actionTypes.RESET_REDIRECT,
  };
};

export const closeDialog = () => {
  return {
    type: actionTypes.CLOSE_DIALOG,
  };
};
