import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';

export const fetchRecipients = (idUser) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setAvailableRecipients(data));
    };

    let obj = {
      url: '/api/messages/fetchrecipients',
      method: 'post',
      data: {idUser: idUser},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      noPendingRequest: true,
      headers: {},
      successMessage: false,
      errorMessage: 'Errore nel recupero degli utenti',
    };
    dispatch(apiCall(obj));
  };
};

export const createThread = (data, idUser) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(fetchThreads(idUser));
    };

    let obj = {
      url: '/api/messages/createthread',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      noPendingRequest: true,
      headers: {},
      successMessage: false,
      errorMessage: 'Errore nella creazione del thread',
    };
    dispatch(apiCall(obj));
  };
};

export const sendMessage = (data) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(fetchMessages(data.selectedThread, data.idUser));
    };

    let obj = {
      url: '/api/messages/send',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      noPendingRequest: true,
      headers: {},
      successMessage: false,
      errorMessage: "Errore nell'invio del messaggio",
    };
    dispatch(apiCall(obj));
  };
};

export const fetchThreads = (idUser) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setThreads(data));
    };

    let obj = {
      url: '/api/messages/fetchthreads',
      method: 'post',
      data: {idUser: idUser},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      noPendingRequest: true,
      headers: {},
      successMessage: false,
      errorMessage: 'Errore nel recupero dei Thread',
    };
    dispatch(apiCall(obj));
  };
};

export const fetchMessages = (idThread, idUser) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setMessages(data));
      dispatch(fetchThreads(idUser));
    };

    let obj = {
      url: '/api/messages/fetchmessages',
      method: 'post',
      data: {idUser: idUser, idThread: idThread},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      noPendingRequest: true,
      headers: {},
      successMessage: false,
      errorMessage: 'Errore nel recupero dei messaggi',
      loader: false,
    };
    dispatch(apiCall(obj));
  };
};

export const setThreads = (data) => {
  return {
    type: actionTypes.SET_THREAD_LIST,
    threadsList: data.threadsList,
  };
};

export const setMessages = (data) => {
  return {
    type: actionTypes.SET_MESSAGE_LIST,
    messagesList: data.messagesList,
    selectedThread: data.selectedThread,
  };
};

export const setUnreadMessage = (data) => {
  return {
    type: actionTypes.SET_UNREAD_MESSAGE,
    unreadMessage: data.unreadMessage,
    notifyThem: data.messageArray,
  };
};

export const setAvailableRecipients = (data) => {
  return {
    type: actionTypes.SET_AVAILABLE_RECIPIENTS,
    recipients: data.recipients,
  };
};

export const setRecipient = (user) => {
  return {
    type: actionTypes.SET_SELECTED_RECIPIENTS,
    selectedRecipient: user,
  };
};

export const removeSelectedRecipient = (idUser) => {
  return {
    type: actionTypes.REMOVE_SELECTED_RECIPIENT,
    selectedRecipient: idUser,
  };
};

export const resetRecipients = () => {
  return {
    type: actionTypes.RESET_RECIPIENT,
  };
};
