import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  unimportedOrdersList: false,
  createOrder: {
    resources: {},
    selectedProperty: {},
    config: {
      currentStep: 0,
      availableSteps: [0],
      allSteps: [0, 1, 2, 3],
    },
    orderTotals: {
      totalProducts: 0,
      totalShipping: 0,
      totalDiscounts: 0,
      totalWrapping: 0,
      totalPaid: 0,
    },
  },
  selectedOrder: false,
  newOrderCreated: false,
  newOrderCreatedId: false,
  //trackingAndLabel: {},
};

const totalOrderCalc = (
  totalProducts,
  totalShipping,
  totalDiscounts,
  totalWrapping,
  totalPaid
) => {
  let calc =
    parseFloat(totalProducts) +
    parseFloat(totalShipping) +
    parseFloat(totalWrapping) -
    parseFloat(totalDiscounts);
  if (isNaN(calc)) {
    return totalPaid;
  }
  return calc.toFixed(2);
};

const setOrderPropertyCoupon = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        coupon: action.coupon,
      },
    },
  };
};

const setOrderPropertyTotalShipping = (state, action) => {
  const totalPaid = totalOrderCalc(
    state.createOrder.orderTotals.totalProducts,
    action.totalShipping,
    state.createOrder.orderTotals.totalDiscounts,
    state.createOrder.orderTotals.totalWrapping,
    state.createOrder.orderTotals.totalPaid
  );

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      orderTotals: {
        ...state.createOrder.orderTotals,
        totalShipping: action.totalShipping,
        totalPaid: totalPaid,
      },
    },
  };
};

const setOrderPropertyTotalWrapping = (state, action) => {
  const totalPaid = totalOrderCalc(
    state.createOrder.orderTotals.totalProducts,
    state.createOrder.orderTotals.totalShipping,
    state.createOrder.orderTotals.totalDiscounts,
    action.totalWrapping,
    state.createOrder.orderTotals.totalPaid
  );

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      orderTotals: {
        ...state.createOrder.orderTotals,
        totalWrapping: action.totalWrapping,
        totalPaid: totalPaid,
      },
    },
  };
};

const setOrderPropertyTotalDiscounts = (state, action) => {
  const totalPaid = totalOrderCalc(
    state.createOrder.orderTotals.totalProducts,
    state.createOrder.orderTotals.totalShipping,
    action.totalDiscounts,
    state.createOrder.orderTotals.totalWrapping,
    state.createOrder.orderTotals.totalPaid
  );

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      orderTotals: {
        ...state.createOrder.orderTotals,
        totalDiscounts: action.totalDiscounts,
        totalPaid: totalPaid,
      },
    },
  };
};

const setOrderResourceCountry = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        selectedCountry: action.country,
      },
    },
  };
};

const setOrderResourceProvince = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        selectedProvince: action.province,
      },
    },
  };
};

const setOrderPropertyCarrier = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        carrier: action.carrier,
      },
    },
  };
};

const setOrderPropertyCurrency = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        currency: action.currency,
      },
    },
  };
};

const setOrderPropertyPayment = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        payment: action.payment,
      },
    },
  };
};

const setOrderPropertyOwner = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        idOwner: action.idOwner,
      },
    },
  };
};

const setOrderPropertyState = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        state: action.state,
      },
    },
  };
};

const setOrdersList = (state, action) => {
  return {
    ...state,
    list: action.orders,
  };
};

const setUnimportedOrdersList = (state, action) => {
  return {
    ...state,
    unimportedOrdersList: action.unimportedOrders,
  };
};

const setOrderPropertyCustomer = (state, action) => {
  let checkIfThereAreProducts =
    state.createOrder?.selectedProperty?.products?.length;
  let availableSteps = [];

  if (checkIfThereAreProducts === 0) {
    availableSteps = [0, 1];
  } else {
    availableSteps = [0, 1, 3, 4];
  }

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        addresses: action.addresses,
      },
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        customer: action.customer,
        address: action.addresses.find(
          (address) =>
            address.id === state.createOrder?.selectedProperty?.address?.id
        )
          ? action.addresses.find(
              (address) =>
                address.id === state.createOrder?.selectedProperty?.address?.id
            )
          : false,
      },
      config: {
        ...state.createOrder.config,
        availableSteps: availableSteps,
      },
    },
  };
};

const setOrderNextStep = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      config: {
        ...state.createOrder.config,
        currentStep: action.step,
      },
    },
  };
};

const setOrderPropertyAddress = (state, action) => {
  let availableSteps = [0, 1, 2];

  let checkIfThereAreProducts =
    state.createOrder?.selectedProperty?.products?.length;

  if (checkIfThereAreProducts !== 0) {
    availableSteps = [0, 1, 2, 3, 4];
  }

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        address: action.address,
      },
      config: {
        ...state.createOrder.config,
        availableSteps: availableSteps,
      },
    },
  };
};

const setOrderPropertyProducts = (state, action) => {
  let availableSteps = [0, 1, 2, 3];

  let checkIfThereAreProducts =
    state.createOrder?.selectedProperty?.products?.length;

  if (checkIfThereAreProducts === 0) {
    availableSteps = [0, 1, 2];
  }

  let totalProducts = 0;

  action.products.forEach((el, index) => {
    el.rowIndex = index;
    totalProducts +=
      parseInt(el.productQuantity) * parseFloat(el.productSellPrice);
  });

  const totalPaid = totalOrderCalc(
    totalProducts.toFixed(2),
    state.createOrder.orderTotals.totalShipping,
    state.createOrder.orderTotals.totalDiscounts,
    state.createOrder.orderTotals.totalWrapping,
    state.createOrder.orderTotals.totalPaid
  );

  return {
    ...state,
    createOrder: {
      resources: {...state.createOrder.resources},
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        products: action.products,
      },
      config: {
        ...state.createOrder.config,
        availableSteps: availableSteps,
      },
      orderTotals: {
        ...state.createOrder.orderTotals,
        totalProducts: totalProducts.toFixed(2),
        totalPaid: totalPaid,
      },
    },
  };
};

const setOrderPropertyProduct = (state, action) => {
  let availableSteps = [0, 1, 2, 3];

  let productsArray = [];

  if (state.createOrder.selectedProperty.products) {
    productsArray = [...state.createOrder.selectedProperty.products];
  }

  productsArray.push(action.product);

  productsArray.forEach((el, index) => {
    el.rowIndex = index;
  });

  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      selectedProperty: {
        ...state.createOrder.selectedProperty,
        products: productsArray,
      },
      config: {
        ...state.createOrder.config,
        availableSteps: availableSteps,
      },
    },
  };
};

const setOrderResourcesAddresses = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        addresses: action.addresses,
      },
    },
  };
};

const setOrderResourcesStart = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        countries: action.countries,
        carriers: action.carriers,
        currencies: action.currencies,
        payments: action.payments,
        states: action.states,
        owners: action.owners,
      },
    },
  };
};

const setOrderResourcesProducts = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        products: action.products,
      },
    },
  };
};

const setOrderResourcesCustomers = (state, action) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: {
        ...state.createOrder.resources,
        customers: action.customers,
      },
    },
  };
};

const setSelectedOrder = (state, action) => {
  return {
    ...state,
    selectedOrder: action.selectedOrder,
  };
};

const resetCreateOrderState = (state) => {
  return {
    ...state,
    createOrder: {
      ...state.createOrder,
      resources: initialState.createOrder.selectedProperty,
      selectedProperty: initialState.createOrder.selectedProperty,
      orderTotals: initialState.createOrder.orderTotals,
      config: initialState.createOrder.config,
    },
  };
};

const setNewOrderCreated = (state, action) => {
  return {
    ...state,
    newOrderCreated: true,
    newOrderCreatedId: action.orderId,
  };
};

const resetNewOrderCreated = (state) => {
  return {
    ...state,
    newOrderCreated: initialState.newOrderCreated,
  };
};

const setShippingNumber = (state, action) => {
  return {
    ...state,
    selectedOrder: {
      ...state.selectedOrder,
      shippingNumber: action.shippingNumber,
    },
  };
};

// const setTrackingAndLabel = (state, action) => {
//   return {
//     ...state,
//     trackingAndLabel: {
//       tracking: action.tracking,
//       label: action.label,
//     },
//   };
// };

// const resetTrackingAndLabel = (state) => {
//   return {
//     ...state,
//     trackingAndLabel: initialState.trackingAndLabel,
//   };
// };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHIPPING_NUMBER:
      return setShippingNumber(state, action);
    case actionTypes.SET_ORDERS_LIST:
      return setOrdersList(state, action);
    case actionTypes.SET_UNIMPORTED_ORDERS_LIST:
      return setUnimportedOrdersList(state, action);
    case actionTypes.SET_ORDER_RESOURCES_CUSTOMERS:
      return setOrderResourcesCustomers(state, action);
    case actionTypes.SET_ORDER_RESOURCES_ADDRESSES:
      return setOrderResourcesAddresses(state, action);
    case actionTypes.SET_ORDER_RESOURCES_PRODUCTS:
      return setOrderResourcesProducts(state, action);
    case actionTypes.SET_ORDER_RESOURCES_START:
      return setOrderResourcesStart(state, action);
    case actionTypes.SET_ORDER_PROPERTY_CUSTOMER:
      return setOrderPropertyCustomer(state, action);
    case actionTypes.SET_ORDER_RESOURCE_PROVINCE:
      return setOrderResourceProvince(state, action);
    case actionTypes.SET_ORDER_RESOURCE_COUNTRY:
      return setOrderResourceCountry(state, action);
    case actionTypes.SET_ORDER_PROPERTY_ADDRESS:
      return setOrderPropertyAddress(state, action);
    case actionTypes.SET_ORDER_PROPERTY_PRODUCT:
      return setOrderPropertyProduct(state, action);
    case actionTypes.SET_ORDER_PROPERTY_PRODUCTS:
      return setOrderPropertyProducts(state, action);
    case actionTypes.SET_ORDER_PROPERTY_CARRIER:
      return setOrderPropertyCarrier(state, action);
    case actionTypes.SET_ORDER_PROPERTY_OWNER:
      return setOrderPropertyOwner(state, action);
    case actionTypes.SET_ORDER_PROPERTY_CURRENCY:
      return setOrderPropertyCurrency(state, action);
    case actionTypes.SET_ORDER_PROPERTY_PAYMENT:
      return setOrderPropertyPayment(state, action);
    case actionTypes.SET_ORDER_PROPERTY_STATE:
      return setOrderPropertyState(state, action);
    case actionTypes.SET_ORDER_PROPERTY_COUPON:
      return setOrderPropertyCoupon(state, action);
    case actionTypes.SET_ORDER_PROPERTY_TOTALSHIPPING:
      return setOrderPropertyTotalShipping(state, action);
    case actionTypes.SET_ORDER_PROPERTY_TOTALWRAPPING:
      return setOrderPropertyTotalWrapping(state, action);
    case actionTypes.SET_ORDER_PROPERTY_TOTALDISCOUNTS:
      return setOrderPropertyTotalDiscounts(state, action);
    case actionTypes.SET_ORDER_NEXT_STEP:
      return setOrderNextStep(state, action);
    case actionTypes.SET_SELECTED_ORDER:
      return setSelectedOrder(state, action);
    case actionTypes.RESET_CREATE_ORDER_STATE:
      return resetCreateOrderState(state, action);
    case actionTypes.SET_NEW_ORDER_CREATED:
      return setNewOrderCreated(state, action);
    case actionTypes.RESET_NEW_ORDER_CREATED:
      return resetNewOrderCreated(state, action);
    //case actionTypes.SET_TRACKING_AND_LABEL:
    //return setTrackingAndLabel(state, action);
    //case actionTypes.RESET_TRACKING_AND_LABEL:
    //return resetTrackingAndLabel(state, action);
    default:
      return state;
  }
};

export default reducer;
