import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';
import {setTableParameters} from './shared';

export const setShelvings = (data) => {
  return {
    type: actionTypes.SET_SHELVINGS_LIST,
    shelvings: data.shelvings,
  };
};

export const setFilteredShelvings = (data) => {
  return {
    type: actionTypes.SET_FILTERED_SHELVINGS_LIST,
    result: data.shelvings,
  };
};

export const setSearchResult = (data) => {
  return {
    type: actionTypes.SET_SHELVINGS_SEARCH_RESULT,
    result: data.result,
  };
};

export const setSelectedShelf = (data) => {
  return {
    type: actionTypes.SET_SELECTED_SHELF,
    result: data,
  };
};

export const searchShelving = (name) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSearchResult(response.data));
    };

    let obj = {
      url: '/api/shelvings/search',
      method: 'post',
      data: {name: name},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      noPendingRequest: true,
      successMessage: null,
      errorMessage: 'Errore di comunicazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getShelvings = (tableParameters, position) => {
  return (dispatch) => {
    let onThen = (response) => {
      if (tableParameters) {
        dispatch(setTableParameters(response.data.tableParameters));
        dispatch(setFilteredShelvings(response.data));
      } else {
        dispatch(setShelvings(response.data));
      }
    };

    let obj = {
      url: '/api/shelvings/list',
      method: 'post',
      data: {tableParameters: tableParameters, position: position},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const getSingleShelf = (idShelf) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSelectedShelf(response.data));
    };

    let obj = {
      url: '/api/shelvings/getsingleshelf/' + idShelf,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: null,
    };

    dispatch(apiCall(obj));
  };
};

export const moveShelf = (from, to, id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getShelvings(false, true));
    };

    let obj = {
      url: '/moveshelf',
      method: 'post',
      data: {
        positionFrom: parseInt(from),
        positionTo: parseInt(to),
        idShelf: parseInt(id),
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Posizione modificata!',
      errorMessage: 'Modifica non effettuata',
      lambda: true,
    };

    dispatch(apiCall(obj));
  };
};

export const createShelving = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getShelvings(false, true));
    };

    let obj = {
      url: '/api/shelvings/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Posizione creata con successo!',
      errorMessage: 'Impossibile creare la posizione',
    };

    dispatch(apiCall(obj));
  };
};

export const moveContentToShelf = (from, to, idUser) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSingleShelf(from.id));
    };

    let obj = {
      url: '/api/shelvings/moveShelfToShelf',
      method: 'post',
      data: {from: from.id, to: to.id, idUser},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Contenuto spostato!',
      errorMessage: 'Modifica non effettuata',
    };

    dispatch(apiCall(obj));
  };
};

export const moveSkusFromShelfToShelf = (idSkus, from, to, idUser) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSingleShelf(from.id));
    };

    let obj = {
      url: '/api/shelvings/moveSkusFromShelfToShelf',
      method: 'post',
      data: {idSkus, from: from.id, to: to.id, idUser},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto spostato!',
      errorMessage: 'Modifica non effettuata',
    };

    dispatch(apiCall(obj));
  };
};
