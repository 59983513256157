import it from '../images/flags/italy.png';
import en from '../images/flags/united-kingdom.png';
import ja from '../images/flags/japan.png';
import fr from '../images/flags/france.png';
import es from '../images/flags/spain.png';
import ru from '../images/flags/russia.png';

export const returnLanguageFlagUrl = (isoLang) => {
  switch (isoLang) {
    case 'it':
      return it;
    case 'en':
      return en;
    case 'ja':
      return ja;
    case 'fr':
      return fr;
    case 'es':
      return es;
    case 'ru':
      return ru;
    default:
      break;
  }
};

export const isAllowed = (groups, userGroup) => {
  return groups.includes(userGroup[0]);
};

export const dateToYYYYMMDD = (date) => {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const res = `${date.getFullYear()}-${month}-${day}`;
  return res;
};
