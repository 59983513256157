// reducer.js
import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  salesData: [],
  loading: false,
  error: null,
};

const salesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SALES_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.SET_SALES_DATA:
      return {
        ...state,
        salesData: action.salesData,
        loading: false,
        error: null,
      };

    case actionTypes.SALES_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default salesReducer;
