import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  descriptionModels: false,
  selectedDescriptionModels: false,
};

const setDescriptionModels = (state, action) => {
  return {
    ...state,
    descriptionModels: action.descriptionModels,
  };
};

const setSelectedDescriptionModel = (state, action) => {
  return {
    ...state,
    selectedDescriptionModel: action.descriptionModel,
  };
};

const editSelectedDescriptionModelValue = (state, action) => {
  switch (action.valueType) {
    case 'name':
      return {
        ...state,
        selectedDescriptionModel: {
          ...state.selectedDescriptionModel,
          name: action.value,
        },
      };
    case 'description':
      return {
        ...state,
        selectedDescriptionModel: {
          ...state.selectedDescriptionModel,
          description: action.value,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DESCRIPTION_MODELS:
      return setDescriptionModels(state, action);
    case actionTypes.SET_SELECTED_DESCRIPTION_MODEL:
      return setSelectedDescriptionModel(state, action);
    case actionTypes.EDIT_SELECTED_DESCRIPTION_MODEL_VALUE:
      return editSelectedDescriptionModelValue(state, action);
    default:
      return state;
  }
};

export default reducer;
