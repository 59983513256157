import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';

export const setSkuOwners = (data) => {
  return {
    type: actionTypes.SET_SKU_OWNERS_LIST,
    skuOwnersList: data.owners,
  };
};

export const setSelectedSkuOwner = (selectedSkuOwner) => {
  return {
    type: actionTypes.SET_SELECTED_SKU_OWNER,
    selectedSkuOwner: selectedSkuOwner,
  };
};

export const getSkuOwners = () => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSkuOwners(response.data));
    };

    let obj = {
      url: '/api/skuowners/list',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};
