import * as actionTypes from './actionTypes';
import {apiCall} from './apiCall';

export const setPreorderProducts = (products) => {
  return {
    type: actionTypes.SET_PREORDER_PRODUCTS_LIST,
    products: products,
  };
};

export const resetSinglePreorderProduct = () => {
  return {
    type: actionTypes.RESET_PREORDER_PRODUCTS_LIST,
  };
};

export const setSelectedPreorderProduct = (data) => {
  return {
    type: actionTypes.SET_SELECTED_PREORDER_PRODUCT,
    data,
  };
};

export const getPreorderProducts = (tableParameters) => {
  return (dispatch) => {
    let onThen = (data) => {
      //dispatch(setTableParameters(data.tableParameters));
      dispatch(setPreorderProducts(data.products));
    };

    let obj = {
      url: '/api/preorders/list',
      method: 'post',
      data: {tableParameters},
      onSuccess: (response) => onThen(response.data),
      onFailure: null,
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore recuperando la lista dei prodotti in prevendita',
    };

    dispatch(apiCall(obj));
  };
};

export const getSinglePreorderProduct = (id) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSelectedPreorderProduct(data));
    };

    let obj = {
      url: '/api/preorders/show/' + id,
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante il recupero del prodotto',
    };

    dispatch(apiCall(obj));
  };
};

export const unlockPreorderProduct = (id, unlockAll) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSinglePreorderProduct(id));
    };

    let obj = {
      url: '/api/preorders/unlock/' + id,
      method: 'post',
      data: {onlySingles: unlockAll === false},
      onSuccess: () => onThen(),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Errore durante lo sblocco degli ordini',
    };

    dispatch(apiCall(obj));
  };
};

export const unlockSinglePreorder = (idProduct, idOrder) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getSinglePreorderProduct(idProduct));
    };

    let obj = {
      url: '/api/preorders/unlocksingle/' + idProduct,
      method: 'post',
      data: {idOrder},
      onSuccess: () => onThen(),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Stato modificato',
      errorMessage: "Errore durante lo sblocco dell'ordine",
    };

    dispatch(apiCall(obj));
  };
};
