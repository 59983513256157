import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  selectedSku: {
    data: {},
    attributes: null,
    tags: null,
    needSave: false,
    syncMarketplace: false,
    selectedPanel: 0,
  },
  stats: {},
  selectedCombination: {},
  relocateList: false,
  search: false,
};

const setSyncMarketplace = (state, action) => {
  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      syncMarketplace: action.idMarketplace,
    },
  };
};

const resetSearchSku = (state) => {
  return {
    ...state,
    search: [],
  };
};

const setSkuSearchResult = (state, action) => {
  return {
    ...state,
    search: action.result && action.result.length > 0 ? action.result : false,
  };
};

const setSkuRelocateList = (state, action) => {
  return {
    ...state,
    relocateList: action.relocateList,
  };
};

const changeSelectedSkuPanel = (state, action) => {
  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      selectedPanel: action.idPanel,
    },
  };
};

const handleChangeToMultipleValues = (state, action) => {
  let obj = [...state.selectedSku.attributes];

  Object.values(obj).forEach((el) => {
    if (el.id === action.id) {
      let pushThat = true;
      el.value.forEach((thisValue, index) => {
        if (thisValue === parseInt(action.value)) {
          el.value.splice(index, 1);
          pushThat = false;
        }
      });
      if (pushThat) {
        el.value.push(parseInt(action.value));
      }
    }
  });

  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      attributes: obj,
      needSave: true,
    },
  };
};

const editSelectedSkuCombination = (state, action) => {
  let obj = [...state?.selectedSku?.data?.combinations];

  Object.values(obj).forEach((el) => {
    if (el.idCombination === action.idCombination) {
      switch (action.valueType) {
        case 'ean13':
          el.ean13 = action.value;
          break;
        case 'reference':
          el.reference = action.value;
          break;
        case 'price':
          el.price = action.value;
          break;
        default:
          break;
      }
    }
  });

  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      data: {
        ...state.selectedSku.data,
        combinations: obj,
      },
      needSave: true,
    },
  };
};

const editSelectedSkuAttribute = (state, action) => {
  let obj = [...state.selectedSku.attributes];

  Object.values(obj).forEach((el) => {
    if (el.id === action.id) {
      el.value = action.value;
    }
  });

  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      attributes: obj,
      needSave: true,
    },
  };
};

const editSelectedSkuTag = (state, action) => {
  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      data: {
        ...state.selectedSku.data,
        tags: action.tags,
      },
      needSave: true,
    },
  };
};

const editSyncSkuAttribute = (state, action) => {
  let obj = [...state.selectedSku.attributes];

  Object.values(obj).forEach((el) => {
    if (el.id === action.idAttribute) {
      Object.values(el.value || []).map((el) => {
        if (el.id === action.idMarketplace) {
          if (action.resourceType === 'active') {
            el.active = action.value;
          }
          if (action.resourceType === 'exported') {
            el.exported = action.value;
          }
          if (action.resourceType === 'forcedTitle') {
            el.forcedTitle = action.value;
          }
          if (action.resourceType === 'forcedPrice') {
            el.forcedPrice = action.value;
          }
          if (action.resourceType === 'forcedCategory') {
            el.forcedCategory = action.value;
          }
        }
        return null;
      });
    }
  });

  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      attributes: obj,
      needSave: true,
    },
  };
};

const resetSelectedSku = (state) => {
  return {
    ...state,
    selectedSku: initialState.selectedSku,
  };
};

const setSelectedSku = (state, action) => {
  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      data: {
        ...action.sku,
        tags: action.sku.tags.map((el) => el.id),
      },
      attributes: action.attributes,
      tags: action.tags,
      image: action.image,
      needSave: false,
    },
  };
};

const setSkusList = (state, action) => {
  return {
    ...state,
    list: action.skus,
  };
};

const setSkusStats = (state, action) => {
  return {
    ...state,
    stats: action.stats,
  };
};

const resetSkusStats = (state) => {
  return {
    ...state,
    stats: {},
  };
};

const setCombinations = (state, action) => {
  return {
    ...state,
    selectedSku: {
      ...state.selectedSku,
      data: {...state.selectedSku.data, combinations: action.combinations},
    },
  };
};

const setCustomCreationsList = (state, action) => {
  return {
    ...state,
    customCreations: action.customCreations,
  };
};

const setCustomCreationsToApproveList = (state, action) => {
  return {
    ...state,
    customCreationsToApprove: action.customCreationsToApprove,
  };
};

const setCustomCreationsToPrintList = (state, action) => {
  return {
    ...state,
    customCreationsToPrint: action.customCreationsToPrint,
  };
};

const setCustomCreationsToPrintForProductList = (state, action) => {
  return {
    ...state,
    customCreationsToPrintForProduct: action.customCreationsToPrintForProduct,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SKUS_LIST:
      return setSkusList(state, action);
    case actionTypes.SET_CUSTOM_CREATIONS_LIST:
      return setCustomCreationsList(state, action);
    case actionTypes.SET_COMBINATIONS_SELECTED:
      return setCombinations(state, action);
    case actionTypes.SET_CUSTOM_CREATIONS_TO_APPROVE_LIST:
      return setCustomCreationsToApproveList(state, action);
    case actionTypes.SET_CUSTOM_CREATIONS_TO_PRINT_LIST:
      return setCustomCreationsToPrintList(state, action);
    case actionTypes.SET_CUSTOM_CREATIONS_TO_PRINT_FOR_PRODUCT_LIST:
      return setCustomCreationsToPrintForProductList(state, action);
    case actionTypes.SET_SKUS_STATS:
      return setSkusStats(state, action);
    case actionTypes.RESET_SKUS_STATS:
      return resetSkusStats(state, action);
    case actionTypes.SET_SELECTED_SKU:
      return setSelectedSku(state, action);
    case actionTypes.RESET_SELECTED_SKU:
      return resetSelectedSku(state, action);
    case actionTypes.EDIT_SELECTED_SKU_ATTRIBUTE:
      return editSelectedSkuAttribute(state, action);
    case actionTypes.EDIT_SELECTED_SKU_TAG:
      return editSelectedSkuTag(state, action);
    case actionTypes.EDIT_SELECTED_Sku_Combination_Attribute:
      return editSelectedSkuCombination(state, action);
    case actionTypes.EDIT_MULTIPLE_SELECTED_SKU:
      return handleChangeToMultipleValues(state, action);
    case actionTypes.CHANGE_SELECTED_SKU_PANEL:
      return changeSelectedSkuPanel(state, action);
    case actionTypes.SET_SKU_SEARCH_RESULT:
      return setSkuSearchResult(state, action);
    case actionTypes.SET_SKUS_RELOCATE_LIST:
      return setSkuRelocateList(state, action);
    case actionTypes.RESET_SEARCH_SKU:
      return resetSearchSku(state, action);
    case actionTypes.SET_SYNC_MARKETPLACE:
      return setSyncMarketplace(state, action);
    case actionTypes.EDIT_SYNC_SKU_ATTRIBUTE:
      return editSyncSkuAttribute(state, action);
    default:
      return state;
  }
};

export default reducer;
