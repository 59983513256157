import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  shelvingsList: false,
  selectedInventory: false,
  selectedShelf: false,
  singleShelfLocations: false,
  singleShelfLocationsChecked: false,
};

const setCheckInventoriesList = (state, action) => {
  return {
    ...state,
    list: action.inventories,
  };
};

const setCheckInventoryShelvingsList = (state, action) => {
  return {
    ...state,
    shelvingsList: action.inventoryShelvings,
  };
};

const setSelectedCheckInventory = (state, action) => {
  return {
    ...state,
    selectedInventory: action.selectedInventory,
  };
};

const setSelectedCheckInventoryShelf = (state, action) => {
  return {
    ...state,
    selectedShelf: action.selectedShelf,
  };
};

const setInventorySingleShelfLocations = (state, action) => {
  return {
    ...state,
    singleShelfLocations: action.singleShelfLocations,
  };
};

const setInventorySingleShelfLocationsChecked = (state, action) => {
  return {
    ...state,
    singleShelfLocationsChecked: action.singleShelfLocationsChecked,
  };
};

const addInventorySingleShelfLocationsChecked = (state, action) => {
  if (
    !state.singleShelfLocationsChecked.find(
      (o) =>
        o.idProduct === action.toAdd.idProduct &&
        o.idSku === action.toAdd.idSku &&
        o.idCombination === action.toAdd.idCombination
    )
  ) {
    return {
      ...state,
      singleShelfLocationsChecked: [
        ...state.singleShelfLocationsChecked,
        action.toAdd,
      ],
    };
  }
  return {
    ...state,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHECK_INVENTORIES_LIST:
      return setCheckInventoriesList(state, action);
    case actionTypes.SET_CHECK_INVENTORY_SHELVINGS_LIST:
      return setCheckInventoryShelvingsList(state, action);
    case actionTypes.SET_SELECTED_CHECK_INVENTORY:
      return setSelectedCheckInventory(state, action);
    case actionTypes.SET_SELECTED_CHECK_INVENTORY_SHELF:
      return setSelectedCheckInventoryShelf(state, action);
    case actionTypes.SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS:
      return setInventorySingleShelfLocations(state, action);
    case actionTypes.SET_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED:
      return setInventorySingleShelfLocationsChecked(state, action);
    case actionTypes.SET_SINGLE_LOCATIONS_CHECK:
      return setInventorySingleShelfLocationsChecked(state, action);
    case actionTypes.ADD_CHECK_INVENTORY_SINGLE_SHELF_LOCATIONS_CHECKED:
      return addInventorySingleShelfLocationsChecked(state, action);
    default:
      return state;
  }
};

export default reducer;
