import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {closeModal} from './modals';

export const setLocations = (data) => {
  return {
    type: actionTypes.SET_LOCATIONS_LIST,
    locations: data.locations,
    sortKey: data.sortKey,
    sortOrder: data.sortOrder,
    searchData: data.searchData,
    currentPage: data.currentPage,
    rowsPerPage: data.rowsPerPage,
    resultsCount: data.resultsCount,
    pageCount: data.pageCount,
  };
};

export const setSkuLocationShelf = (data) => {
  return {
    type: actionTypes.SET_SKU_LOCATIONS,
    locations: data.locations,
  };
};

export const createLocation = (data) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(getLocations());
      dispatch(closeModal());
    };

    let obj = {
      url: '/api/locations/create',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Prodotto creato con successo',
      errorMessage: 'Errore durante la creazione',
    };

    dispatch(apiCall(obj));
  };
};

export const getLocations = (searchAndSortData, page) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setLocations(response.data));
    };

    let obj = {
      url: '/api/locations/list?page=' + page,
      method: 'post',
      data: searchAndSortData,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const editLocation = (data, id, filters = false, page = 1) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      dispatch(getLocations(filters, page));
    };

    let obj = {
      url: '/api/locations/edit/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ubicazione modificata con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const destroyLocation = (data, id, filters = false, page = 1) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(closeModal());
      dispatch(getLocations(filters, page));
    };

    let obj = {
      url: '/api/locations/destroy/' + id,
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ubicazione eliminata con successo',
      errorMessage: 'Errore durante la modifica',
    };

    dispatch(apiCall(obj));
  };
};

export const getSkuLocationShelf = (data) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSkuLocationShelf(response.data));
    };

    let obj = {
      url: '/api/locations/sku/getshelf',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      errorMessage: 'Errore durante la richiesta delle ubicazioni',
    };

    dispatch(apiCall(obj));
  };
};
