import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  cartsList: false,
  selectedCart: false,
  needSave: true,
};

const setCartsList = (state, action) => {
  return {
    ...state,
    cartsList: action.cartsList,
  };
};

const editSelectedCartValue = (state, action) => {
  let newSelectedCart = {...state.selectedCart};

  newSelectedCart[action.name] = action.value;

  return {
    ...state,
    selectedCart: newSelectedCart,
    needSave: true,
  };
};

const setSelectedCart = (state, action) => {
  return {
    ...state,
    selectedCart: action.selectedCart.cart,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CARTS_LIST:
      return setCartsList(state, action);
    case actionTypes.SET_SELECTED_CART:
      return setSelectedCart(state, action);
    case actionTypes.EDIT_SELECTED_CART_VALUE:
      return editSelectedCartValue(state, action);
    default:
      return state;
  }
};

export default reducer;
