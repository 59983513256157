import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
// Material
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles/';

const AlertMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const AlertMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);

const AlertHandler = (props) => {
  const [anchorAlert, setAnchorAlert] = React.useState(null);

  const openMenu = (event) => {
    setAnchorAlert(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorAlert(null);
  };

  const sanitizeText = (text) => {
    return text.replaceAll('<br />', '');
  };

  return (
    <React.Fragment>
      <IconButton color="inherit" onClick={openMenu}>
        <Badge
          // badgeContent={null}
          color="primary"
          variant={props.unreadAlerts > 0 ? 'dot' : null}
        >
          {props.unreadAlerts === 0 ? (
            <NotificationsIcon />
          ) : (
            <NotificationsActiveIcon />
          )}
          {/* <NotificationsIcon /> */}
        </Badge>
      </IconButton>
      <AlertMenu
        anchorEl={anchorAlert}
        keepMounted
        open={Boolean(anchorAlert)}
        onClose={closeMenu}
      >
        {(props.recentAlerts || []).map((el, index) => {
          return (
            <Link
              to="/alerts"
              style={{textDecoration: 'none', color: 'black'}}
              key={index}
              onClick={closeMenu}
            >
              <AlertMenuItem>
                <ListItemText
                  primary={sanitizeText(el.text)}
                  secondary={el.alertDate}
                />
              </AlertMenuItem>
            </Link>
          );
        })}
        <AlertMenuItem>
          <ListItemText primary={'...'} />
        </AlertMenuItem>
      </AlertMenu>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    unreadAlerts: state.alerts.unread,
    recentAlerts: state.alerts.recentAlerts,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AlertHandler);
