import {apiCall} from './apiCall';
import * as actionTypes from './actionTypes';
import {setTableParameters} from './shared';

export const setSysPanel = (selectedPanel) => {
  return {
    type: actionTypes.SET_SYS_PANEL,
    selectedPanel: selectedPanel,
  };
};

export const setSysPanelLabel = (label) => {
  return {
    type: actionTypes.SET_SYS_PANEL_LABELS,
    label: label,
  };
};

export const setSysPanelLogData = (logsData) => {
  return {
    type: actionTypes.SET_SYS_PANEL_LOG_DATA,
    logsData: logsData,
  };
};

export const setSysPanelGenericData = (genericData) => {
  return {
    type: actionTypes.SET_SYS_PANEL_GENERIC_DATA,
    genericData: genericData,
  };
};

export const setSysPanelFilterDate = (filterDate) => {
  return {
    type: actionTypes.SET_SYS_PANEL_FILTER_DATE,
    filterDate: filterDate,
  };
};

export const setSysPanelUnlockRelocations = (data) => {
  return {
    type: actionTypes.SET_UNLOCK_RELOCATION_ROWS,
    rows: data.rows || [],
  };
};

export const setSysPanelClosingReservations = (data) => {
  return {
    type: actionTypes.SET_CLOSING_RESERVATIONS_ROWS,
    closingReservations: data.closingReservations || [],
  };
};

export const setSysPanelHandlePickingBatches = (data) => {
  return {
    type: actionTypes.SET_HANDLE_PICKING_BATCHES,
    pickingBatchesEnabled: data.pickingBatchesEnabled,
    pickingBatches: data.pickingBatches,
  };
};

export const setSysPanelCheckQuantity = (data) => {
  return {
    type: actionTypes.SET_CHECK_QUANTITY_ROWS,
    rows: data.quantity || [],
  };
};

export const updateSysPanelCheckQuantity = (toUpdate) => {
  return {
    type: actionTypes.UPDATE_CHECK_QUANTITY_ROWS,
    toUpdate: toUpdate,
  };
};

export const editSystemConfig = (name, value, idConfig) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/syspanel/editconfig',
      method: 'post',
      data: {
        name: name,
        value: value,
        idConfig: idConfig,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Modifica effettuata con successo',
      errorMessage: 'Errore',
    };

    dispatch(apiCall(obj));
  };
};

export const getSysPanelLogs = (tableParameters) => {
  return (dispatch) => {
    let onThen = (response) => {
      dispatch(setSysPanelLogData(response.data.logs));
      dispatch(setTableParameters(response.data.tableParameters));
    };

    let obj = {
      url: '/api/syspanel/logs',
      method: 'post',
      data: {
        tableParameters: tableParameters,
      },
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: null,
      errorMessage: 'Nessun risultato',
    };

    dispatch(apiCall(obj));
  };
};

export const fetchUnlockRelocationRows = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSysPanelUnlockRelocations(data));
    };

    let obj = {
      url: '/api/relocate/get',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const fetchClosingReservationRows = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSysPanelClosingReservations(data));
    };

    let obj = {
      url: '/api/syspanel/fetchclosingreservations',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const fetchHandleShippingBatches = () => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSysPanelHandlePickingBatches(data));
    };

    let obj = {
      url: '/api/shipmentcenter/collect/getpickingbatches',
      method: 'post',
      data: {},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const checkQuantity = (idOwner) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSysPanelCheckQuantity(data));
    };

    let obj = {
      url: '/api/syspanel/check/quantity',
      method: 'post',
      data: {
        idOwner,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const fixPsQuantity = (
  idOwner,
  idProductMarketplace,
  idCombinationMarketplace,
  newQuantity,
  oldQuantity
) => {
  return (dispatch) => {
    let onThen = (data) => {
      if (data.success && data.success.stock_available) {
        dispatch(updateSysPanelCheckQuantity(data.success.stock_available));
      }
    };

    let obj = {
      url: '/api/syspanel/fix/quantity',
      method: 'post',
      data: {
        idOwner,
        idProductMarketplace,
        idCombinationMarketplace,
        newQuantity,
        oldQuantity,
      },
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const unlockRelocation = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(fetchUnlockRelocationRows());
    };

    let obj = {
      url: '/api/relocate/unlock',
      method: 'post',
      data: {id},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const deleteRelocation = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(fetchUnlockRelocationRows());
    };

    let obj = {
      url: '/api/relocate/delete',
      method: 'post',
      data: {id},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const deleteClosingReservation = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(fetchClosingReservationRows());
    };

    let obj = {
      url: '/api/syspanel/deleteclosingreservation',
      method: 'post',
      data: {id},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage: 'Errore di comunicazione con il sistema',
    };
    dispatch(apiCall(obj));
  };
};

export const deletePickingBatch = (id) => {
  return (dispatch) => {
    let onThen = () => {
      dispatch(fetchHandleShippingBatches());
    };

    let obj = {
      url: '/api/syspanel/deletepickingbatch',
      method: 'post',
      data: {id},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: false,
      errorMessage:
        'Errore nel tentativo di cancellazione del batch di raccolta',
    };
    dispatch(apiCall(obj));
  };
};

export const createMultiplePdf = (orders, name) => {
  return (dispatch) => {
    let onThen = (data) => {
      dispatch(setSysPanelLabel(data.result.labelUrl));
    };

    let obj = {
      url: '/api/syspanel/brtMultiplePdf',
      method: 'post',
      data: {orders, name},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Pdf creato con successo',
      errorMessage: 'Errore nel tentativo di creazione di pdf multipli',
    };
    dispatch(apiCall(obj));
  };
};

export const setMultipleOrderAsShipped = (orders) => {
  return (dispatch) => {
    let onThen = () => {};

    let obj = {
      url: '/api/syspanel/brtMultipleShipped',
      method: 'post',
      data: {orders},
      onSuccess: (response) => onThen(response.data),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Ordini spediti con successo',
      errorMessage: "Errore nell'invio di ordini al sistema di spedizione",
    };
    dispatch(apiCall(obj));
  };
};

export const callLambdaForLabel = (data) => {
  return (dispatch) => {
    let onThen = () => {
      console.info('finito');
    };

    let obj = {
      url: '/create/shipping/direct',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Etichetta creata!',
      errorMessage: 'Errore della lambda',
      lambda: true,
      lambdaUrl: 'https://sc-api.ivemind.com',
      lambdaKey: 'e9d3c11b-5ad4-4fec-b05b-a47a7f8b153f:',
    };

    dispatch(apiCall(obj));
  };
};

export const callLambdaForLabelDispatch = (data) => {
  return (dispatch) => {
    let onThen = () => {
      console.info('finito');
    };

    let obj = {
      url: '/create/shipping/dispatch',
      method: 'post',
      data: data,
      onSuccess: (response) => onThen(response),
      onFailure: () => {},
      label: '',
      headers: {},
      successMessage: 'Etichetta creata!',
      errorMessage: 'Errore della lambda',
      lambda: true,
      lambdaUrl: 'https://sc-api.ivemind.com',
      lambdaKey: 'e9d3c11b-5ad4-4fec-b05b-a47a7f8b153f:',
    };

    dispatch(apiCall(obj));
  };
};
