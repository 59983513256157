import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  list: false,
  searchResult: [],
  selectedShelf: [],
};

const setShelvingsList = (state, action) => {
  return {
    ...state,
    list: action.shelvings.list,
  };
};

const setFilteredShelvingsList = (state, action) => {
  return {
    ...state,
    searchResult: action.result.list,
  };
};

const setShelvingsSearchResult = (state, action) => {
  return {
    ...state,
    searchResult: action.result,
  };
};

const setSelectedShelf = (state, action) => {
  return {
    ...state,
    selectedShelf: action.result?.skuLocations,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHELVINGS_LIST:
      return setShelvingsList(state, action);
    case actionTypes.SET_FILTERED_SHELVINGS_LIST:
      return setFilteredShelvingsList(state, action);
    case actionTypes.SET_SHELVINGS_SEARCH_RESULT:
      return setShelvingsSearchResult(state, action);
    case actionTypes.SET_SELECTED_SHELF:
      return setSelectedShelf(state, action);
    default:
      return state;
  }
};

export default reducer;
