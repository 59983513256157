import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  boxesList: false,
};

const setBoxesList = (state, action) => {
  return {
    ...state,
    boxesList: action.boxesList,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BOXES_LIST:
      return setBoxesList(state, action);
    default:
      return state;
  }
};

export default reducer;
