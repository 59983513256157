import * as types from './types';

const initialState = {};

const collections = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COLLECTION:
      return {
        ...state,
        [action.name]: action.data,
      };
    case types.SET_COLLECTION:
      return {
        ...state,
        [action.name]: [...action.data],
      };
    case types.UPDATE_COLLECTION:
      return {
        ...state,
        [action.name]: state[action.name].map((elem) => {
          if (elem.id === action.id) {
            return action.data;
          } else {
            return elem;
          }
        }),
      };
    default:
      return state;
  }
};

export default collections;
