import {toast} from 'react-toastify';
import * as actionTypes from '../Actions/actionTypes';

const initialState = {
  isRequest: false,
  dialog: {
    open: false,
    body: false,
    title: false,
    agree: false,
    disagree: false,
    problems: false,
  },
  tableParameters: {
    ordersList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'ordersList',
    },
    unimportedOrdersList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'unimportedOrdersList',
    },
    checkInventoriesList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'checkInventoriesList',
    },
    checkInventoryShelvingsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'checkInventoryShelvingsList',
    },
    shelvingsViewedList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'shelvingsViewedList',
    },
    missionReportsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'missionReportsList',
    },
    marketplacesList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'marketplacesList',
    },
    sysPanelLogsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'sysPanelLogsList',
    },
    productBrandsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'productBrandsList',
    },
    descriptionModels: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'descriptionModels',
    },
    skusList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'skusList',
    },
    productsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'productsList',
    },
    preorderProductsList: {
      sortKey: 'date',
      sortOrder: 'asc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 100,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'preorderProductsList',
    },
    baseProductsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'baseProductsList',
    },
    customCreationsList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'customCreationsList',
    },
    customCreationsToApproveList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'customCreationsToApproveList',
    },
    customCreationsToPrintList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'customCreationsToPrintList',
    },
    customCreationsToPrintForProductList: {
      sortKey: 'quantity',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'customCreationsToPrintForProductList',
    },
    suppliersList: {
      sortKey: 'id',
      sortOrder: 'desc',
      searchData: [],
      currentPage: 1,
      rowsPerPage: 15,
      resultsCount: 0,
      pageCount: 0,
      collections: {},
      indexName: 'suppliersList',
    },
  },
  relocationRows: {
    shelvings: [],
    rows: [],
    count: 0,
    reserved: [],
    myReservation: {},
    needRelocation: false,
  },
  inventoryControlRows: [],
  inventoryRemovalRows: [],
  itemToCheck: false,
  itemToPick: false,
  barcodeReader: false,
  qrCodeReader: false,
  currentPosition: false,
  forceRedirect: false,
};

const changeRelocationRowValue = (state, action) => {
  let newMyReservation = {...state.relocationRows.myReservation};

  newMyReservation[action.name] = action.value;

  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      myReservation: newMyReservation,
    },
  };
};

const changeDialogPayloadValue = (state, action) => {
  let newPayload = {...state.dialog.parameters.payload};

  newPayload[action.name] = action.value;

  return {
    ...state,
    dialog: {
      ...state.dialog,
      parameters: {
        ...state.dialog.parameters,
        payload: newPayload,
      },
    },
  };
};

const resetItemToCheck = (state, action) => {
  return {
    ...state,
    itemToCheck: false,
  };
};

const changeTableParameter = (state, action) => {
  let newParams = {...state.tableParameters[action.indexName]};

  (action.parameters || []).forEach((el) => {
    switch (el.type) {
      case 'sortKey':
        newParams.sortKey = el.value;
        break;
      case 'sortOrder':
        newParams.sortOrder = el.value;
        break;
      case 'searchData':
        let array = [...newParams.searchData];

        let founded = false;

        Object.values(array).forEach((arrayEl, index) => {
          if (arrayEl.name === el.name) {
            if (el.value === '') {
              array.splice(index, 1);
              founded = true;
            } else {
              arrayEl.value = el.value;
              founded = true;
            }
          }
        });

        if (!founded) {
          array.push({name: el.name, label: el.label, value: el.value});
        }

        newParams.searchData = array;
        break;
      case 'currentPage':
        newParams.currentPage = el.value;
        break;
      case 'rowsPerPage':
        newParams.rowsPerPage = el.value;
        break;
      case 'resultsCount':
        newParams.resultsCount = el.value;
        break;
      case 'pageCount':
        newParams.pageCount = el.value;
        break;
      default:
        break;
    }
  });

  return {
    ...state,
    tableParameters: {
      ...state.tableParameters,
      [action.indexName]: newParams,
    },
  };
};

const setTableParameters = (state, action) => {
  return {
    ...state,
    tableParameters: {
      ...state.tableParameters,
      [action.indexName]: action.tableParameters,
    },
  };
};

const resetTableParameters = (state, action) => {
  return {
    ...state,
    tableParameters: initialState.tableParameters,
  };
};

const setItemToCheck = (state, action) => {
  return {
    ...state,
    itemToCheck: action.item,
  };
};

const resetItemToPick = (state, action) => {
  return {
    ...state,
    itemToPick: false,
  };
};

const setItemToPick = (state, action) => {
  return {
    ...state,
    itemToPick: action.item,
  };
};

const resetCurrentPosition = (state, action) => {
  return {
    ...state,
    currentPosition: initialState.currentPosition,
  };
};

const setCurrentPosition = (state, action) => {
  return {
    ...state,
    currentPosition: action.currentPosition,
  };
};

const toggleBarcodeReader = (state, action) => {
  return {
    ...state,
    barcodeReader: action.value,
  };
};

const toggleQrCodeReader = (state, action) => {
  return {
    ...state,
    qrCodeReader: action.value,
  };
};

const setNeedRelocation = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      needRelocation: action.needRelocation,
    },
  };
};

const setRelocationRows = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      shelvings: action.shelvings,
      carts: action.carts,
      rows: action.rows || [],
      count: action.count,
      reserved: action.reserved,
    },
  };
};

const setRelocationSelectedShelf = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      selectedShelf: action.selectedShelf,
      selectedCart: null,
    },
  };
};

const setRelocationSelectedCart = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      selectedCart: action.selectedCart,
      selectedShelf: null,
    },
  };
};

const setRelocateReservation = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      myReservation: action.payload,
    },
  };
};

const resetRelocateReservation = (state, action) => {
  return {
    ...state,
    relocationRows: {
      ...state.relocationRows,
      myReservation: {},
    },
  };
};

const setInventoryControlRows = (state, action) => {
  if (
    state.inventoryControlRows.length === 0 &&
    action.inventoryControl.length > 0
  ) {
    toast.info('Richiesto controllo inventario');
  }

  return {
    ...state,
    inventoryControlRows: action.inventoryControl,
  };
};

const setInventoryRemovalRows = (state, action) => {
  if (
    state.inventoryRemovalRows.length === 0 &&
    action.inventoryRemoval.length > 0
  ) {
    toast.info('Richiesta rimozione manuale prodotti');
  }

  return {
    ...state,
    inventoryRemovalRows: action.inventoryRemoval,
  };
};

const setInventoryRemovalWithInfoRows = (state, action) => {
  return {
    ...state,
    inventoryRemovalWithInfoRows: action.rows,
  };
};

const openDialog = (state, action) => {
  return {
    ...state,
    dialog: {
      ...state.dialog,
      open: action.index,
      parameters: action.parameters,
    },
  };
};

const removeSupplierOrderProblem = (state, action) => {
  let newProblems = [...state.dialog.problems];

  newProblems.forEach((el, index) => {
    if (el.id === action.id) {
      newProblems.splice(index, 1);
    }
  });

  return {
    ...state,
    dialog: {
      ...state.dialog,
      problems: newProblems,
    },
  };
};

const closeDialog = (state, action) => {
  return {
    ...state,
    dialog: initialState.dialog,
  };
};

const setStartRequest = (state, action) => {
  return {
    ...state,
    isRequest: action.isRequest,
  };
};

const setStopRequest = (state, action) => {
  return {
    ...state,
    isRequest: false,
  };
};

const setForceRedirect = (state, action) => {
  return {
    ...state,
    forceRedirect: action.type === 'RESET_REDIRECT' ? false : action.url,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_START_REQUEST:
      return setStartRequest(state, action);
    case actionTypes.SET_STOP_REQUEST:
      return setStopRequest(state, action);
    case actionTypes.OPEN_DIALOG:
      return openDialog(state, action);
    case actionTypes.CLOSE_DIALOG:
      return closeDialog(state, action);
    case actionTypes.CHANGE_DIALOG_PAYLOAD_VALUE:
      return changeDialogPayloadValue(state, action);
    case actionTypes.REMOVE_SUPPLIER_ORDER_PROBLEM:
      return removeSupplierOrderProblem(state, action);
    case actionTypes.SET_RELOCATION_ROWS:
      return setRelocationRows(state, action);
    case actionTypes.SET_NEED_RELOCATION:
      return setNeedRelocation(state, action);
    case actionTypes.SET_RELOCATE_RESERVATION:
      return setRelocateReservation(state, action);
    case actionTypes.RESET_RELOCATE_RESERVATION:
      return resetRelocateReservation(state, action);
    case actionTypes.SET_RELOCATION_SELECTED_CART:
      return setRelocationSelectedCart(state, action);
    case actionTypes.SET_RELOCATION_SELECTED_SHELF:
      return setRelocationSelectedShelf(state, action);
    case actionTypes.SET_INVENTORY_CONTROL_ROWS:
      return setInventoryControlRows(state, action);
    case actionTypes.SET_INVENTORY_REMOVAL_ROWS:
      return setInventoryRemovalRows(state, action);
    case actionTypes.SET_INVENTORY_REMOVAL_WITH_INFO_ROWS:
      return setInventoryRemovalWithInfoRows(state, action);
    case actionTypes.TOGGLE_BARCODE_READER:
      return toggleBarcodeReader(state, action);
    case actionTypes.TOGGLE_QRCODE_READER:
      return toggleQrCodeReader(state, action);
    case actionTypes.SET_CURRENT_POSITION:
      return setCurrentPosition(state, action);
    case actionTypes.RESET_CURRENT_POSITION:
      return resetCurrentPosition(state, action);
    case actionTypes.SET_NEAREST_ITEM_TO_CHECK:
      return setItemToCheck(state, action);
    case actionTypes.FORCE_REDIRECT:
      return setForceRedirect(state, action);
    case actionTypes.RESET_REDIRECT:
      return setForceRedirect(state, action);
    case actionTypes.RESET_ITEM_TO_CHECK:
      return resetItemToCheck(state, action);
    case actionTypes.SET_ITEM_TO_PICK:
      return setItemToPick(state, action);
    case actionTypes.RESET_ITEM_TO_PICK:
      return resetItemToPick(state, action);
    case actionTypes.SET_TABLE_PARAMETERS:
      return setTableParameters(state, action);
    case actionTypes.RESET_TABLE_PARAMETERS:
      return resetTableParameters(state, action);
    case actionTypes.CHANGE_TABLE_PARAMETERS:
      return changeTableParameter(state, action);
    case actionTypes.CHANGE_RELOCATION_ROW_VALUE:
      return changeRelocationRowValue(state, action);
    default:
      return state;
  }
};

export default reducer;
